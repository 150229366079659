/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// const React = require("react")
// const GlobalContextProvider = require('./src/context/globalContextProvider').default

// exports.wrapRootElement = ({ element }) => {
//   return (
//     <GlobalContextProvider>
//       {element}
//     </GlobalContextProvider>
//   )
// }


import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export const onRouteUpdateDelayed = () => {
    console.log("Slow route")
  }
// import {pumps, sunTowns, towns} from './pumps'
import roundTo from "round-to"
import minBy from "lodash/minBy";
import mean from "lodash/mean"
import sum from "lodash/sum"
import times from "lodash/times"
import { pmt, npv, nper } from 'financial'
const systemLossFactor = 0.02 // 2%

const hourlyYield = [
  { hour: "06:00", percent: 0 },
  { hour: "07:00", percent: 3 },
  { hour: "08:00", percent: 32 },
  { hour: "09:00", percent: 50 },
  { hour: "10:00", percent: 75 },
  { hour: "11:00", percent: 90 },
  { hour: "12:00", percent: 100 },
  { hour: "13:00", percent: 99 },
  { hour: "14:00", percent: 85 },
  { hour: "15:00", percent: 65 },
  { hour: "16:00", percent: 30 },
  { hour: "17:00", percent: 1 },
  { hour: "18:00", percent: 0 },
]

const flowsCalculation = ({ town, flowThd, sizingFactor }) => {

  const averageSunFlowPerHour = hourlyYield.map(hour => {
    return { ...hour, flow: Math.round((hour.percent / 100) * flowThd) }
  })

  const averageSizingFactorFlowPerHour = hourlyYield.map(hour => {
    return {
      ...hour,
      flow: Math.round((hour.percent / 100) * flowThd * sizingFactor),
    }
  })

  const averageDriveFlowPerHour = hourlyYield.map(hour => {
    return {
      ...hour,
      flow: Math.round(
        (hour.percent / 100) * flowThd * sizingFactor >= flowThd
          ? flowThd
          : (hour.percent / 100) * flowThd * sizingFactor
      ), 
    }
  }).map(a=>({...a, flowPercent: a.flow/flowThd })) // i want it as a percentage (decimal) for later too


  // so now we have the ave 'total' for the day
  const averageDriveFlowPerDay = Math.round(
    averageDriveFlowPerHour.reduce(
      (accumulator, currentValue) => accumulator + currentValue.flow,
      0
    )
  )

  const averageDriveFlowPerHourIdeal = hourlyYield.map(hour => {
    return {
      ...hour,
      flow: Math.round(
        (hour.percent / 100) * 100 * sizingFactor >= 100
          ? 100
          : (hour.percent / 100) * 100 * sizingFactor
      ), 
    }
  })

  const averageDriveFlowPercentPerDay = Math.round(
    averageDriveFlowPerHourIdeal.reduce(
      (accumulator, currentValue) => accumulator + currentValue.flow,
      0
    )
  )

  const normalSunHours = 6.5 // would normally use 6.5, christiaan used 6.7/6.8 in his calcs

  // now need to send through month usage for town, this is how much per day in the town
  const monthsFlowsPerDay = town.sunHours.map(monthHours => {
    const litres = (averageDriveFlowPerDay * monthHours / normalSunHours)  //6.5 being the normal average
    return Math.round(litres)
  })

 
  const monthsFlowsPerDayPercent = town.sunHours.map(monthHours => {
    const percent = (averageDriveFlowPercentPerDay * monthHours / normalSunHours )
    return Math.round(percent)
  })  

  // const monthsFlowsPerDayPercentTotal = Math.round(
  //   monthsFlowsPerDayPercent.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue,
  //     0
  //   ) / 12
  // )

  // console.log({ monthsFlowsPerDayPercent, monthsFlowsPerDayPercentTotal })

  // so now we have the ave 'total' for the month flows per day (for the town)
  const averageDriveFlowPerDayAtTown = Math.round(
    monthsFlowsPerDay.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ) / 12
  )

  const averageDriveFlowPerDayAtTownCubicLiters = roundTo(averageDriveFlowPerDayAtTown * 0.001, 2)

  console.log({ monthsFlowsPerDay, monthsFlowsPerDayPercent })

  return {
    // averageFlowPerDay,
    averageDriveFlowPerDay,
    averageSunFlowPerHour,
    monthsFlowsPerDay,
    averageSizingFactorFlowPerHour,
    averageDriveFlowPerHour,
    averageDriveFlowPerDayAtTown,
    averageDriveFlowPerDayAtTownCubicLiters,
    monthsFlowsPerDayPercent,
    // monthsFlowsPerDayPercentTotal
  }
}

// so we take the inputs of the drive results then do the things
const flowsCalculationIrrigation = ({ averageDriveFlowPerHour, averageDriveFlowPerDay , powerRating, pumpHoursPerDay }) => {

  console.log({ averageDriveFlowPerHour, averageDriveFlowPerDay , powerRating, pumpHoursPerDay })
  // doing the irrigation stuff from here

  const bottomMinConst = 0.08
  const topMaxConst = 0.02

  // now for each hour do get the min and max

  // hard coding for now
  const getHoursFromNoon = (i) => {
    if(i===0 || i===12){
      return 13
    }
    if(i===1 || i===11){
      return 11
    }
    if(i===2 || i===10){
      return 9
    }
    if(i===3 || i===9){
      return 7
    }
    if(i===4 || i===8){
      return 5
    }
    if(i===5 || i===7){
      return 3
    }
    if(i===6){
      return 1
    }else{
      return 0
    }
  } 

  const hourResults = averageDriveFlowPerHour.map((a, i)=>{
    const bottomMin = a.flowPercent>bottomMinConst ? powerRating * a.flowPercent : 0
    const topMax = bottomMin>0 ? bottomMin * (1-topMaxConst) : 0
    const totalSavingsPercent = topMax / powerRating
    const timeFromNoon = getHoursFromNoon(i)
    const powerFromGrid = powerRating - topMax // pump output - power from solar
    return {
      ...a,
      bottomMin,
      topMax, // power from solar
      totalSavingsPercent,
      timeFromNoon,
      powerFromGrid,
      powerFromSolar: topMax
    }
  })

  // so now do the calculations from 12 noon both ways, sure there is a better way

  // first set the nominal at 12 noon
  hourResults[6].pumpPercent = pumpHoursPerDay>1 ? 1: pumpHoursPerDay

  // from 12 and down
  for (let i = 5; i >= 0; i--) {
    if(hourResults[i+1].pumpPercent===1){
      hourResults[i].pumpPercent = pumpHoursPerDay>hourResults[i].timeFromNoon?1:(pumpHoursPerDay-hourResults[i+1].timeFromNoon)/2
    }else{
      hourResults[i].pumpPercent = 0
    }    
  }

   // from 12 and up
   for (let i = 7; i <= 12; i++) {
    if(hourResults[i-1].pumpPercent===1){
      hourResults[i].pumpPercent = pumpHoursPerDay>hourResults[i].timeFromNoon?1:(pumpHoursPerDay-hourResults[i-1].timeFromNoon)/2
    }else{
      hourResults[i].pumpPercent = 0
    }    
  }


  return hourResults
}


const recomendedDriveCalculation = ({ voltage, powerRating, drives }) => {
  //filter voltage
  //get the smallest pump size for greater than or equal to power rating

  const filteredVoltageAndRating = drives
    .filter(d => parseFloat(d.acVoltage) === parseFloat(voltage))
    .filter(s => parseFloat(s.pumpMototSize) >= parseFloat(powerRating))

  const calculatedDrives = minBy(filteredVoltageAndRating, "pumpMototSize")

  // returning an array just incase we need to show another version, closest drive
  return calculatedDrives
}

const stringsCalculation = ({ powerRating, panelOptions, optimalMin }) => {

  // filter voltage
  // get the smallest pump size for greater than or equal to power rating
  // returning an array just incase we need to show another version, closest drive
  // return calculatedDrives

  const wattRequirement = parseFloat(powerRating) * 1000 * 1.6

  const optionFilter = panelOptions.map(a => {
    const minNoPanelsPerString = Math.ceil(
      parseFloat(optimalMin) / parseFloat(a.voc)
    )
    const noStrings = Math.ceil(
      wattRequirement / (minNoPanelsPerString * parseFloat(a.pmax))
    )
    return {
      minNoPanelsPerString,
      noStrings,
      minPower: parseFloat(a.pmax) * minNoPanelsPerString * noStrings,
      ...a,
    }
  })

  const recomendedOption = minBy(optionFilter, "minPower")
  // const minPv = parseFloat(optimalMin)/recomendedOption.voc
  // const panelsPerString = Math.ceil(minPv)

  const sizingFactor = roundTo(
    recomendedOption.minPower / (powerRating * 1000),
    2
  )
  const totalNoPanels =
    recomendedOption.noStrings * recomendedOption.minNoPanelsPerString
  const systemVoc = roundTo(recomendedOption.minNoPanelsPerString * recomendedOption.voc, 2)

  return {
    recomendedOption,
    sizingFactor,
    totalNoPanels,
    systemVoc,
  }
}

const recomendedDriveCalculationTwo = ({ voltage, powerRating, drives }) => {
  //filter voltage
  //get the smallest pump size for greater than or equal to power rating
  const filteredVoltage = drives
    .filter(d => parseFloat(d.acVoltage) === parseFloat(voltage))
  
  // the 240 is only 1 drive so we can just return it
  if(filteredVoltage.length===1){
    return filteredVoltage[0]
  }
  // .filter(s => parseFloat(s.pumpMotorSize) >= parseFloat(powerRating))
  // trying to get the one that exact matches or the one 1 below
  const indexOfFit = filteredVoltage.findIndex(a => a.pumpMotorSize >= parseFloat(powerRating))
  if(indexOfFit<0){
    //cannot find it
    return null
  }
  const recomendedDrive = filteredVoltage[indexOfFit].pumpMotorSize === parseFloat(powerRating) || indexOfFit === 0 ? filteredVoltage[indexOfFit] : filteredVoltage[indexOfFit - 1]

  return recomendedDrive
}

const stringsCalculationTwo = ({ recomendedDrive, vmp, voc, watt, make, powerRating, hybrid }) => {
  const sizingFactor = hybrid ? 1.2 : 1.6 // if hybrid sizing factor down to 1.2
  const minV = Math.ceil(recomendedDrive.minVMP1 / vmp)
  const maxV = Math.floor(recomendedDrive.maxVOC / voc)
  const diffV = maxV - minV + 1 // ie the amount of loops we should make
  const wattNeeded = powerRating * 1000 * sizingFactor
  let stringsOptions = []

  // do a for for now till it clicks better
  for (let noStrings = 1; noStrings <= diffV; noStrings++) {
    const panelsPerString = minV + noStrings - 1
    const wattPerString = panelsPerString * watt
    const oneStringWatt = wattPerString / wattNeeded
    const stringNeeded = 1 / (oneStringWatt === 0 ? 1 : oneStringWatt)
    const stringNeededRounded = Math.ceil(stringNeeded)
    const totalWatt = stringNeededRounded <= 0 ? 0 : wattPerString * stringNeededRounded
    const totalPanels = stringNeededRounded * panelsPerString
    // console.log({noStrings, panelsPerString, wattPerString, oneStringWatt, stringNeeded, stringNeededRounded, totalWatt })
    if (totalWatt >= 0) {
      stringsOptions.push({ noStrings, panelsPerString, wattPerString, oneStringWatt, stringNeeded, stringNeededRounded, totalWatt, totalPanels, vmp, voc, watt, make })
    }
  }
  if (stringsOptions.length === 0) {
    return null // could not work out?
  } else {
    return minBy(stringsOptions, 'totalWatt')
  }

}

const daysInMonth = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31]

export const irrigation = ({
  driveResult,
  powerRating,
  taxRate = 27,
  systemCost = 1020000,
  deposit = 10,
  loanPeriod = 10,
  interestRate = 7.5,
  electricityIncrease5Yr = 8.0,
  electricityIncrease6Yr = 6.0,
  averageEnergyCost = 1.1,
  monthlyConsumptionCost = [0,0,0,0,0,0,0,0,0,0,0,0],
}) => {

  // get the average of the consumption if above 0 (months that have a value)
  const averageConsumption = mean(monthlyConsumptionCost.filter(a=>a>0)) 
  // fill in the blanks of each month
  const monthsKwh = monthlyConsumptionCost.map(a=>a>0?a:averageConsumption)
  // power per month/days
  const monthsKwhPerMonth = monthsKwh.map((a, i)=>a/daysInMonth[i])

  const monthsPumpHoursPerDay = monthsKwhPerMonth.map(a=>a/powerRating)

  // driveResult.monthsFlowsPerDayPercent

  const monthsHourly = monthsPumpHoursPerDay.map((monthHours, i)=>{
    const hourResults = flowsCalculationIrrigation({averageDriveFlowPerHour: driveResult.averageDriveFlowPerHour, averageDriveFlowPerDay: driveResult.averageDriveFlowPerDay, powerRating: parseFloat(powerRating), pumpHoursPerDay: monthHours })
    const hourlySavingsNoClimate = sum(hourResults.map(a=>a.totalSavingsPercent*a.pumpPercent))
    const hourlySavingsWithClimate = hourlySavingsNoClimate * driveResult.monthsFlowsPerDayPercent[i]/100 // mixed % and decimal damn...
    const totalSavings = sum( hourResults.map((a,i)=>a.totalSavingsPercent))
    const potentialSavings = totalSavings * driveResult.monthsFlowsPerDayPercent[i]/100 * averageEnergyCost * daysInMonth[i] * powerRating
    const actualSavings = hourlySavingsWithClimate * averageEnergyCost * daysInMonth[i] * powerRating
    return {hourResults, hourlySavingsWithClimate, actualSavings, potentialSavings, climate: driveResult.monthsFlowsPerDayPercent[i]/100}
  })


  // const hourResults = averageDriveFlowPerHour.map((a, i)=>{
  //   const bottomMin = a.flowPercent>bottomMinConst ? powerRating * a.flowPercent : 0
  //   const topMax = bottomMin>0 ? bottomMin * (1-topMaxConst) : 0
  //   const totalSavingsPercent = topMax / powerRating
  //   const timeFromNoon = getHoursFromNoon(i)
  //   const powerFromGrid = powerRating - topMax // pump output - power from solar
  //   return {
  //     ...a,
  //     bottomMin,
  //     topMax, // power from solar
  //     totalSavingsPercent,
  //     timeFromNoon,
  //     powerFromGrid,
  //     powerFromSolar: topMax
  //   }
  // })

  const hourlyTotals = hourlyYield.map((a,i)=>({
    hour: a.hour,
    powerFromSolar: monthsHourly[0].hourResults[i].topMax,
    powerFromGrid: powerRating - monthsHourly[0].hourResults[i].topMax,
    pumpOutput: powerRating
  }))


  const totalActualSavings = sum(monthsHourly.map(a=>a.actualSavings))



  let loanYears = []

  for (let i = 0; i < 15; i++) {
    if(i===0){
      loanYears[i] = {electricitySavings: totalActualSavings, taxSavings: taxRate/100*systemCost }
    }else{
      if(i>=6-1){ // after year 6 electricity price changes
        loanYears[i] = {electricitySavings: loanYears[i-1].electricitySavings*(1+(electricityIncrease6Yr/100)), taxSavings:0}
      }else{
        loanYears[i] = {electricitySavings: loanYears[i-1].electricitySavings*(1+(electricityIncrease5Yr/100)), taxSavings:0}
      }
    }

    loanYears[i] = {...loanYears[i], electricityPlusTaxSavings: loanYears[i].electricitySavings + loanYears[i].taxSavings}
    if(i===0){
      const loanRepayment = (pmt(interestRate/100/12,loanPeriod*12,systemCost-(systemCost*deposit/100))*12)-(systemCost*deposit/100)
      loanYears[i] = {...loanYears[i], loanRepayment}
    }else{
      const loanRepayment = (pmt(interestRate/100/12,loanPeriod*12,systemCost-(systemCost*deposit/100))*12)
      loanYears[i] = {...loanYears[i], loanRepayment}
    }

    // loan payment is done
    if(i>loanPeriod-1){
      loanYears[i] = {...loanYears[i], loanRepayment:0}
    }

    loanYears[i] = {...loanYears[i], annualCashflow: loanYears[i].loanRepayment + loanYears[i].electricityPlusTaxSavings}

    if(i===0){
      loanYears[i] = {...loanYears[i], cumulativeCashFlow: loanYears[i].annualCashflow}
    }else{
      loanYears[i] = {...loanYears[i], cumulativeCashFlow: loanYears[i].annualCashflow + loanYears[i-1].cumulativeCashFlow}
    }

    // expense: loanYears[i].loanRepayment, income: loanYears[i].electricityPlusTaxSavings,
    loanYears[i] = {...loanYears[i], year:i+1} //  expenseAndIncome: loanYears[i].loanRepayment+loanYears[i].electricityPlusTaxSavings, costPerKwh: (-1*loanYears[i].loanRepayment)/totalActualSavings}
  }


  // for the current income after cost we need to work from the system cost and then add on each year
  const systemCostLoan = -1*sum(loanYears.map(a=>a.loanRepayment))
  for (let i = 0; i < loanYears.length; i++) {
    if(i===0){
      // use system cost on first year
      loanYears[i] = {...loanYears[i], expenseAfterIncome: -1*systemCostLoan+loanYears[i].electricityPlusTaxSavings, costPerKwh: (-1*loanYears[i].loanRepayment)/totalActualSavings}
    }else{
      loanYears[i] = {...loanYears[i], expenseAfterIncome: loanYears[i-1].expenseAfterIncome + loanYears[i].electricityPlusTaxSavings, costPerKwh: (-1*loanYears[i].loanRepayment)/totalActualSavings}
    }
  }

  const solarKwhGenerationCostLoan = mean(loanYears.map(a=>a.costPerKwh))
  const breakEvenYearLoan = loanYears.find((a)=>a.expenseAfterIncome>0)?loanYears.find((a)=>a.expenseAfterIncome>0).year-1:14
  // console.log({loanYears})
  // now we need the months
  console.log('diff', loanYears[breakEvenYearLoan].expenseAfterIncome, loanYears[breakEvenYearLoan-1].expenseAfterIncome)
  const diffAtEnd = loanYears[breakEvenYearLoan].expenseAfterIncome+(-1*loanYears[breakEvenYearLoan-1].expenseAfterIncome)
  const breakEvenMonthsLoan = Math.ceil((1-(loanYears[breakEvenYearLoan].expenseAfterIncome/diffAtEnd))*12)

  const npvRate = 0.05 // from excel don't really know what it is
  // we need to adjust this slightly to match the excel npv (see the warning on this package for npv)
  // basically a 0 because excel starts at t=1 (no initial wealth)
  const netPresentValLoan = npv(npvRate, [0, ...loanYears.map(a=>a.annualCashflow)])

  // Compute the number of periodic payments
  // const paybackPeriod = nper()

  let cashYears = []

  for (let i = 0; i < 15; i++) {
    if(i===0){
      cashYears[i] = {monthlyOutlay: -1*systemCost, electricitySavings: totalActualSavings, taxSavings: taxRate/100*systemCost }
    }else{
      if(i>=6-1){ // after year 6 electricity price changes
        cashYears[i] = {monthlyOutlay: 0, electricitySavings: cashYears[i-1].electricitySavings*(1+(electricityIncrease6Yr/100)), taxSavings:0}
      }else{
        cashYears[i] = {monthlyOutlay: 0, electricitySavings: cashYears[i-1].electricitySavings*(1+(electricityIncrease5Yr/100)), taxSavings:0}
      }
    }

    cashYears[i] = {...cashYears[i], electricityPlusTaxSavings: cashYears[i].electricitySavings + cashYears[i].taxSavings}

    cashYears[i] = {...cashYears[i], annualCashflow: cashYears[i].monthlyOutlay + cashYears[i].electricityPlusTaxSavings}

    if(i===0){
      cashYears[i] = {...cashYears[i], cumulativeCashFlow: cashYears[i].annualCashflow}
    }else{
      cashYears[i] = {...cashYears[i], cumulativeCashFlow: cashYears[i].annualCashflow + cashYears[i-1].cumulativeCashFlow}
    }

    cashYears[i] = {...cashYears[i], year:i+1}
  }

  // console.log({cashYears})


  const netPresentValCash = npv(npvRate, [0, ...cashYears.map(a=>a.annualCashflow)])

  const solarKwhGenerationCostCash = (systemCost/totalActualSavings)/15 // ave over 15 years

  const breakEvenYearCash = cashYears.find((a)=>a.cumulativeCashFlow>0)?cashYears.find((a)=>a.cumulativeCashFlow>0).year-1:14
  // now we need the months
  const diffAtEndCash = cashYears[breakEvenYearCash].cumulativeCashFlow+(-1*cashYears[breakEvenYearCash-1].cumulativeCashFlow)
  const breakEvenMonthsCash = Math.ceil((1-(cashYears[breakEvenYearCash].cumulativeCashFlow/diffAtEndCash))*12)


 return {monthsKwh, monthsPumpHoursPerDay, monthsHourly, loanYears, cashYears, netPresentValLoan, netPresentValCash, hourlyTotals, systemCostLoan, solarKwhGenerationCostLoan, breakEvenYearLoan: breakEvenYearLoan===0?0:breakEvenYearLoan-1, breakEvenMonthsLoan, solarKwhGenerationCostCash, breakEvenMonthsCash, breakEvenYearCash: breakEvenYearCash===0?0:breakEvenYearCash-1}
  
}

export const veichiManualCalculation = ({
  powerRating,
  flow,
  town,
  customer,
  phone,
  email,
  voltage,
  panelMake,
  panelVMP,
  panelVOC,
  panelWatt,
  manual,
  drive,
  numberOfStrings,
  panelsPerString,
  hybrid
}) => {

  const totalNumberPanels = numberOfStrings * panelsPerString
  const totalPVWatt = panelWatt * totalNumberPanels
  const sizingFactor = roundTo(totalPVWatt / (powerRating * 1000), 2)
  const pump = drive

  const flowsCalc = flowsCalculation({
    town,
    flowThd: flow !== 0 ? parseFloat(flow*1000): 100*1000,
    sizingFactor,
  })

  const thd = flow !== 0 ? parseFloat(flow*1000): 100*1000

  const panelsCalc = {
    noStrings: numberOfStrings,
    stringNeededRounded: numberOfStrings,
    panelsPerString: panelsPerString,
    totalWatt: totalPVWatt,
    totalPanels: totalNumberPanels,
    vmp: panelVMP,
    voc: panelVOC,
    watt: panelWatt,
    make: panelMake
  }

  // stringsOptions.push({noStrings, panelsPerString, wattPerString, oneStringWatt, stringNeeded, stringNeededRounded, totalWatt, totalPanels, vmp, voc, watt, make })
  const systemVoc = roundTo(panelsCalc.panelsPerString * panelVOC, 2)
  const systemVmp = roundTo(panelsCalc.panelsPerString * panelVMP, 2)

  const warnings = []

  if (!hybrid && sizingFactor < 1.6) {
    warnings.push('Sizing Factor Below the Recommended Minimum of 1.6. We Recommend a sizing of 1.2 for True Hybrid Function and and at least a 1.6 Sizing Factor for Pure Solar.')
  }else if (hybrid && sizingFactor < 1.2) {
    warnings.push('Sizing Factor Below the Recommended Minimum of 1.2. We Recommend a sizing of 1.2 for True Hybrid Function and and at least a 1.6 Sizing Factor for Pure Solar.')
  }

  if (systemVoc > pump.maxVOC) {
    warnings.push(`Voc exceeds maximum of ${pump.maxVOC}`)
  }
  if (systemVmp < pump.minVMP1) {
    warnings.push(`VMP below minimum of ${pump.minVMP1}`)
  }



  // console.log({ drive: pump, panels: panelsCalc, flows: flowsCalc, thd: flow, powerRating, town, sizingFactor, systemVoc, warnings, manual })
  return ({ drive: pump, panels: panelsCalc, flows: flowsCalc, thd, powerRating, town, sizingFactor, systemVoc, warnings, manual, systemVmp })

}

// this is the one we using 17 April 2023
export const veichiRecomendedCalculationTwo = ({
  powerRating,
  flow,
  town,
  customer,
  phone,
  email,
  voltage,
  panelMake,
  panelVMP,
  panelVOC,
  panelWatt,
  hybrid,
  drives,
}) => {
  const recomendedDrive = recomendedDriveCalculationTwo({
    voltage,
    powerRating,
    drives,
  })

  // return ({error: 'Cannot find a recomended Drive'})

  if (recomendedDrive) {
    const panelsCalc = stringsCalculationTwo({
      recomendedDrive,
      vmp: panelVMP,
      voc: panelVOC,
      watt: panelWatt,
      make: panelMake,
      powerRating,
      hybrid
    })

    if (panelsCalc) {

      const sizingFactor = roundTo(panelsCalc.wattPerString * panelsCalc.stringNeededRounded / (powerRating * 1000), 2)
      const totalWatt = panelsCalc.wattPerString * panelsCalc.stringNeededRounded
      const totalNumberPanels = panelsCalc.stringNeededRounded * panelsCalc.panelsPerString
      const systemVoc = roundTo(panelsCalc.panelsPerString * panelVOC, 2)
      const systemVmp = roundTo(panelsCalc.panelsPerString * panelVMP, 2)

      const flowsCalc = flowsCalculation({
        town,
        flowThd: flow !== 0 ? parseFloat(flow*1000): 100*1000,
        sizingFactor,
      })

      const thd = flow !== 0 ? parseFloat(flow*1000): 100*1000

      const warnings = []

      if (!hybrid && sizingFactor < 1.6) {
        warnings.push('Sizing Factor Below the Recommended Minimum of 1.6. We Recommend a sizing of 1.2 for True Hybrid Function and and at least a 1.6 Sizing Factor for Pure Solar.')
      }else if (hybrid && sizingFactor < 1.2) {
        warnings.push('Sizing Factor Below the Recommended Minimum of 1.2. We Recommend a sizing of 1.2 for True Hybrid Function and and at least a 1.6 Sizing Factor for Pure Solar.')
      }

      // console.log({ drive: recomendedDrive, panels: panelsCalc, flows: flowsCalc, thd: flow, powerRating, town, sizingFactor, systemVoc, warnings, manual: false })
      return ({ drive: recomendedDrive, panels: panelsCalc, flows: flowsCalc, thd, powerRating, town, sizingFactor, systemVoc, warnings, manual: false, systemVmp })

    } else {
      return ({ error: 'Cannot find a recomended Drive' })
    }




    // return ({error: 'YESSS'})

    // setRecomendResult({ drive: recomendedDrive, panels, flows, thd: flow })
  } else {
    // setRecomendResultError(true)
    return ({ error: 'Cannot find a recomended Drive' })
  }


  // if (recomendedDrive) {
  //   const panelsCalc = stringsCalculation({
  //     optimalMin: recomendedDrive.optimalMin,
  //     powerRating,
  //     panelOptions: panels,
  //   })
  //   const flowsCalc = flowsCalculation({
  //     town,
  //     flowThd: parseFloat(flowThd),
  //     sizingFactor: panelsCalc.sizingFactor,
  //   })
  //   return ({ drive: recomendedDrive, panels: panelsCalc, flows: flowsCalc, thd: flowThd, powerRating, town })
  //   // setRecomendResult({ drive: recomendedDrive, panels, flows, thd: flow })
  // } else {
  //   // setRecomendResultError(true)
  //   return ({error: 'Cannot find a recomended Drive'})
  // }
}

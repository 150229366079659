export const ADMIN = "ADMIN"
export const RESELLER = "RESELLER"
export const GOLDRESELLER = "GOLDRESELLER"
export const DIAMONDRESELLER = "DIAMONDRESELLER"
export const PLATINUMRESELLER = "PLATINUMRESELLER"
export const APPLICANT = "APPLICANT"
export const STOCK = "STOCK" // can edit stock
export const REP = "REP"
export const WAREHOUSE = "WAREHOUSE"
export const NAMIBIA_GOLD = "NAMIBIA_GOLD"
export const NAMIBIA_RESELLER = "NAMIBIA_RESELLER"

// not sure what im doing
const roleCanAccessSizing = (role) => (role === RESELLER || role === GOLDRESELLER || role === PLATINUMRESELLER || role === DIAMONDRESELLER || role === NAMIBIA_GOLD || role === NAMIBIA_RESELLER || role === ADMIN || role === REP)

const roleCanAccessAdmin = (role) => (role === ADMIN)

const roleCanAccessStock = (role) => (role === ADMIN || role === STOCK || role === REP)

const roleCanAccessUsers = (role) => (role === ADMIN || role === REP)

const roleCanAccessWarehouse = (role) => (role === ADMIN || role === WAREHOUSE)

export const canAccessWarehouse = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => roleCanAccessWarehouse(a))
  )
}

export const canAccessUsers = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => roleCanAccessUsers(a))
  )
}

export const canAccessStock = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => roleCanAccessStock(a))
  )
}

export const canAccessSizing = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => roleCanAccessSizing(a))
  )
}

export const canAccessAgentApplication = roles => {
  return (
    roles === null || (roles && roles.role && roles.role.length === 0)
  )
}

export const hasApplicantRole = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => a === 'APPLICANT')
  )
}

export const canAccessAdmin = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => roleCanAccessAdmin(a))
  )
}

export const isRep = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => a === REP)
  )
}

export const hasPriceRole = (roles, role) => {
  return !!roles.role.find(a => a === role)
}

export const isNamibia = roles => {
  return (
    roles &&
    roles.role &&
    roles.role.find(a => a === NAMIBIA_RESELLER || a === NAMIBIA_GOLD)
  )
}

export const rolesList = [ADMIN, RESELLER, GOLDRESELLER, DIAMONDRESELLER, APPLICANT, PLATINUMRESELLER, STOCK, REP, WAREHOUSE, NAMIBIA_GOLD, NAMIBIA_RESELLER]
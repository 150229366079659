import { ADMIN, GOLDRESELLER, hasPriceRole, PLATINUMRESELLER, REP, RESELLER, WAREHOUSE, DIAMONDRESELLER, NAMIBIA_RESELLER, NAMIBIA_GOLD } from "./checkRoles"
import orderBy from "lodash/orderBy"
export const roundCashFormat = (value) => new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', minimumFractionDigits: 2 }).format(value)

export const firebaseDate = (date) => {
  if (date && date.seconds) {
    return new Date(date.seconds * 1000)
  } else {
    return date
  }
}

export const getMonthFromNumber = (num) => {
  switch (num) {
    case 0:
      return `January`
    case 1:
      return `February`
    case 2:
      return `March`
    case 3:
      return `April`
    case 4:
      return `May`
    case 5:
      return `June`
    case 6:
      return `July`
    case 7:
      return `August`
    case 8:
      return `September`
    case 9:
      return `October`
    case 10:
      return `November`
    case 11:
      return `December`
    default:
      return `Unknown`
  }
}

export const getMonthSmallFromNumber = (num) => {
  switch (num) {
    case 0:
      return `Jan`
    case 1:
      return `Feb`
    case 2:
      return `Mar`
    case 3:
      return `Apr`
    case 4:
      return `May`
    case 5:
      return `Jun`
    case 6:
      return `Jul`
    case 7:
      return `Aug`
    case 8:
      return `Sep`
    case 9:
      return `Oct`
    case 10:
      return `Nov`
    case 11:
      return `Dec`
    default:
      return `Unk`
  }
}

// export const LOW_STOCK = 'Low'
// export const OUT_OF_STOCK = 'Out of Stock'
// export const HIGH_STOCK = 'High'
// export const MEDIUM_STOCK = 'Medium'

export const OUT_OF_STOCK = 'Out of Stock'
export const IN_STOCK = 'In Stock'

export const getStockValue = (stock) => {
  if (stock >= 2) {
    return IN_STOCK
  } else {
    return OUT_OF_STOCK
  }
}

export const getPriceBasedOnRoleNocodb = ({ roles, product }) => {
  if (hasPriceRole(roles, ADMIN) || hasPriceRole(roles, REP) || hasPriceRole(roles, WAREHOUSE)) {
    return product['Gold Price']
  } else if (hasPriceRole(roles, GOLDRESELLER)) {
    return product['Gold Price']
  } else if (hasPriceRole(roles, DIAMONDRESELLER)) {
    return product['Diamond Price']
  } else if (hasPriceRole(roles, PLATINUMRESELLER)) {
    return product['Platinum Price']
  } else if (hasPriceRole(roles, RESELLER)) {
    return product['Reseller Price']
  } else if (hasPriceRole(roles, NAMIBIA_RESELLER)) {
    return product['Namibia Reseller Price']
  } else if (hasPriceRole(roles, NAMIBIA_GOLD)) {
    return product['Namibia Gold Price']
  } else {
    return product['Retail Price']
  }
}

export const getRetailPrice = ({ roles, product }) => {
  if (hasPriceRole(roles, NAMIBIA_RESELLER) || hasPriceRole(roles, NAMIBIA_GOLD)) {
    return product['Namibia Retail Price']
  }
  return product['Retail Price']
}

export const getRoleTextOutput = ({ roles }) => {
  if (hasPriceRole(roles, ADMIN) || hasPriceRole(roles, REP) || hasPriceRole(roles, WAREHOUSE)) {
    return "Gold Reseller"
  } else if (hasPriceRole(roles, GOLDRESELLER)) {
    return "Gold Reseller"
  } else if (hasPriceRole(roles, DIAMONDRESELLER)) {
    return "Diamond Reseller"
  } else if (hasPriceRole(roles, PLATINUMRESELLER)) {
    return "Platinum Reseller"
  } else if (hasPriceRole(roles, RESELLER)) {
    return "Reseller"
  } else if (hasPriceRole(roles, NAMIBIA_RESELLER)) {
    return "Namibia Reseller"
  } else if (hasPriceRole(roles, NAMIBIA_GOLD)) {
    return "Namibia Gold Reseller"
  } else {
    return "Retail"
  }
}

// sort products by order and then by category
export const sortProducts = (products) => {
  return orderBy(products, ['Categories.Order', 'Categories.Title', 'Order', 'Title'], ['asc', 'asc', 'asc', 'asc'])
}


export const convertImageFromNocodb = (images, params) => {
  const p = params || 'tr:h-200,w-200,cm-pad_resize,bg-FFFFFF'
  if (images && images.length > 0 && images[0] && images[0].url) {
    return images[0].url.replace('https://data.scout.energy/download/noco/Cedar', `https://ik.imagekit.io/8bvsrwlri/${p}`)
  } else {
    return undefined
  }
}

export const getStockBasedOnProfileLocation = ({ location, product }) => {
  let stock = 0
  if (location === 'Cape Town') {
    stock = product['CPT Stock'] ?? 0
  } else {
    stock = product['JHB Stock'] ?? 0
  }


  if (stock < 0) {
    stock = 0
  }
  return stock
}
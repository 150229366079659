import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { useSelector, useDispatch } from "react-redux"
// import { StoreProvider } from "./src/state/store-context"
import * as Sentry from "@sentry/gatsby";
import { Button } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

import createStore, {
  setupFirebase,
  startAuth,
  getSavedAuth,
} from "./src/state/store"

const ErrorFallbackTop = ({ error }) => {
  console.error(error)
  return (
    <Alert style={{ width: '100%', marginTop: '20px' }} severity="error">
      <AlertTitle>Error</AlertTitle>
      <div>
        We hit an error loading something, click this button to try recover:{' '}
        <Button
          variant="contained"
          size="small"
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </div>
    </Alert>
  )
}

const StartUp = ({ children }) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setupFirebase())
    dispatch(getSavedAuth())
  }, [dispatch])

  useEffect(() => {
    if (state.db.ready) {
      dispatch(startAuth())
    }
  }, [state.db.ready, dispatch])

  return <Sentry.ErrorBoundary fallback={<ErrorFallbackTop/>}><>{children}</></Sentry.ErrorBoundary>
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return (
    <Provider store={store}>
        <StartUp>{element}</StartUp>
    </Provider>
  )
}

import {
  configureStore,
  createSlice,
  getDefaultMiddleware,
  isPlain,
} from "@reduxjs/toolkit"
import { PumpCalulation, PumpCalulationAdjusted } from "../components/pumps/pumps-new"
import { veichiRecomendedCalculation, veichiRecomendedCalculationTwo, veichiManualCalculation } from "../components/pumps/veichi";
import { canAccessAdmin } from "../utils/checkRoles"
import { getStockBasedOnProfileLocation } from "../utils/common"
// import nasaBackup from '../../raw-backups/nasa.json'
// import batteriesBackup from '../../raw-backups/batteries.json'
import firebase from 'firebase/app'
import * as Sentry from "@sentry/gatsby";

const isBrowser = typeof window !== `undefined`

// let firebase = null
// if(isBrowser){
//   firebase = require("firebase/app")
//   require('firebase/firestore')
//   require('firebase/storage')
// }

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOAMIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.GATSBY_APP_ID,
  measurementId: process.env.GATSBY_MEASUREMENT_ID
}

let auth,
  db = null

const dbInitalState = {
  loggedIn: false,
  appLoading: true,
  userProfile: null,
  uid: null,
  ready: false,
  login: null,
  register: null,
  forgotPassword: null,
  isNewUser: false,
  banner: null,
  lastBasicPumpInput: null,
  feedback: null,
  auditsOn: true,
  quotes: null,
  irrigationInput: null,
  categories: null,
  products: null,
  currentQuote: null,
  alertMessage: null,
  // passwordReset:

  // login:{
  //     loading: false,
  //     result: null,
  //     error: null
  // }
}

const dbSlice = createSlice({
  name: "db",
  initialState: dbInitalState,
  reducers: {
    dbReady: (state, action) => ({ ...state, ready: action.payload }),
    setAuditsOn: (state, action) => ({ ...state, auditsOn: action.payload }),
    requestTowns: (state) => ({
      ...state,
      towns: { loading: true, msg: null, error: false, data: null },
    }),
    finishTowns: (state, action) => ({
      ...state,
      towns: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),
    unAuthenticated: () => {
      localStorage.removeItem("authUser")
      return { ...dbInitalState, ready: true }
    },
    authenticated: (state, action) => ({
      ...state,
      login: { loading: false, msg: null, error: false },
      loggedIn: true,
      userProfile: action.payload.profile,
      uid: action.payload.uid,
    }),
    requestLogin: (state) => ({
      ...state,
      loggedIn: false,
      login: { loading: true, msg: null, error: false },
    }),
    finishLogin: (state, action) => ({
      ...state,
      loggedIn: false,
      isNewUser: action.payload.data,
      login: {
        loading: action.payload.loading,
        msg: null,
        error: action.payload.error,
      },
    }),
    requestForgotPassword: (state) => ({
      ...state,
      forgotPassword: { loading: true, msg: null, error: false },
    }),
    finishForgotPassword: (state, action) => ({
      ...state,
      forgotPassword: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
      },
    }),
    requestRegister: (state) => ({
      ...state,
      register: { loading: true, msg: null, error: false },
    }),
    finishRegister: (state, action) => ({
      ...state,
      register: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
      },
    }),
    requestUsers: (state) => ({
      ...state,
      users: { loading: true, msg: null, error: false, data: null },
    }),
    finishUsers: (state, action) => ({
      ...state,
      users: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
      roles: null,
    }),

    requestUpdateRoles: (state) => ({
      ...state,
      roles: { loading: true, msg: null, error: null, data: null },
    }),
    finishUpdateRoles: (state, action) => ({
      ...state,
      roles: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    // finishVerifyEmail: (state, action) => ({
    //   ...state,
    //   profile: {
    //     loading: action.payload.loading,
    //     msg: action.payload.msg,
    //     error: action.payload.error,
    //     data: action.payload.data,
    //   },
    // }),

    requestUpdateProfile: (state) => ({
      ...state,
      profile: { loading: true, msg: null, error: null, data: null },
    }),
    finishUpdateProfile: (state, action) => ({
      ...state,
      profile: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestProducts: (state) => ({
      ...state,
      products: { loading: true, msg: null, error: false, data: null },
    }),
    finishProducts: (state, action) => ({
      ...state,
      products: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    connectProductImage: (state, action) => {
      const newProducts = state.products.data.map(a => {
        if (a.id === action.payload.id) {
          return { ...a, imageUrl: action.payload.url }
        }
        return a
      })
      return {
        ...state,
        products: { ...state.products, data: newProducts },
      }
    },

    requestBasicPumpCalculation: (state, action) => ({
      ...state,
      lastBasicPumpInput: action.payload,
    }),
    finishBasicPumpCalculation: (state, action) => ({
      ...state,
      basicPumpCalculation: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    clearPumpCalculation: (state, action) => ({
      ...state,
      lastBasicPumpInput: null,
      basicPumpCalculation: {
        loading: false,
        msg: null,
        error: null,
        data: null,
      },
    }),

    requestVeichiCalculation: (state, action) => ({
      ...state,
      lastVeichiInput: action.payload,
    }),
    finishVeichiCalculation: (state, action) => ({
      ...state,
      veichiCalculation: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    clearVeichiCalculation: (state, action) => ({
      ...state,
      lastVeichiInput: null,
      veichiCalculation: {
        loading: false,
        msg: null,
        error: null,
        data: null,
      },
    }),



    setBasicPumpSizingFromSaved: (state, action) => ({
      ...state,
      lastBasicPumpInput: action.payload,
    }),

    // requestAddWarranty: (state) => ({
    //   ...state,
    //   warranty: { loading: true, msg: null, error: false, data: null },

    // }),
    // finishWarranty: (state, action) => ({
    //   ...state,
    //   warranty: {
    //     loading: action.payload.loading,
    //     msg: action.payload.msg,
    //     error: action.payload.error,
    //     data: action.payload.data,
    //   },
    // }),

    requestUserApplication: (state) => ({
      ...state,
      application: { loading: true, msg: null, error: false, data: null },

    }),

    requestAddApplication: (state) => ({
      ...state,
      application: { loading: true, msg: null, error: false, data: null },

    }),
    finishApplication: (state, action) => ({
      ...state,
      application: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    // requestWarrantyList: (state) => ({
    //   ...state,
    //   warranties: { loading: true, msg: null, error: false, data: null },

    // }),

    // finishWarrantyList: (state, action) => ({
    //   ...state,
    //   warranties: {
    //     loading: action.payload.loading,
    //     msg: action.payload.msg,
    //     error: action.payload.error,
    //     data: action.payload.data,
    //   },
    // }),

    addbanner: (state, action) => ({
      ...state,
      banner: action.payload,

    }),

    requestAddFlows: (state, action) => ({
      ...state,
    }),

    finishAddFlows: (state, action) => ({
      ...state,
      rawFlows: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestFlowsList: (state) => ({
      ...state,
      flows: { loading: true, msg: null, error: false, data: null },

    }),

    finishFlowsList: (state, action) => ({
      ...state,
      flows: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestAddViechiDrives: (state, action) => ({
      ...state,
    }),

    finishAddVeichiDrives: (state, action) => ({
      ...state,
      rawViechiDrives: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestVeichiDrivesList: (state) => ({
      ...state,
      viechiDrives: { loading: true, msg: null, error: false, data: null },

    }),

    finishVeichiDrivesList: (state, action) => ({
      ...state,
      viechiDrives: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestDownloads: (state) => ({
      ...state,
      downloads: { loading: true, msg: null, error: false, data: null },

    }),

    finishDownloads: (state, action) => ({
      ...state,
      downloads: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    connectDownloadUrls: (state, action) => {
      const newDownloads = state.downloads.data.map(a => {
        if (a.id === action.payload.id) {
          return { ...a, fileUrl: action.payload.url }
        }
        return a
      })
      return {
        ...state,
        downloads: { ...state.downloads, data: newDownloads },
      }
    },

    requestSizingSavedList: (state) => ({
      ...state,
      sizingSaves: { loading: true, msg: null, error: false, data: null },

    }),

    finishSizingSavedList: (state, action) => ({
      ...state,
      sizingSaves: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestFeedback: (state) => ({
      ...state,
      feedback: { loading: true, msg: null, error: false, data: null },

    }),

    finishFeedback: (state, action) => ({
      ...state,
      feedback: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestAudits: (state) => ({
      ...state,
      audits: { loading: true, msg: null, error: false, data: null },

    }),

    finishAudits: (state, action) => ({
      ...state,
      audits: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestAddMppt: (state, action) => ({
      ...state,
    }),

    finishAddMppt: (state, action) => ({
      ...state,
      rawMppt: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestMpptList: (state) => ({
      ...state,
      mppts: { loading: true, msg: null, error: false, data: null },

    }),

    finishMpptList: (state, action) => ({
      ...state,
      mppts: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestStock: (state) => ({
      ...state,
      stock: { loading: true, msg: null, error: false, data: null },

    }),

    finishStock: (state, action) => ({
      ...state,
      stock: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestRawNasa: (state) => ({
      ...state,
      rawNasa: { loading: true, msg: null, error: false, data: null },

    }),

    finishRawNasa: (state, action) => ({
      ...state,
      rawNasa: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    requestBatteries: (state) => ({
      ...state,
      batteries: { loading: true, msg: null, error: false, data: null },

    }),

    finishBatteries: (state, action) => ({
      ...state,
      batteries: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestInverters: (state) => ({
      ...state,
      inverters: { loading: true, msg: null, error: false, data: null },

    }),

    finishInverters: (state, action) => ({
      ...state,
      inverters: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    requestSolarProjects: (state) => ({
      ...state,
      solarProjects: { loading: true, msg: null, error: false, data: null },

    }),

    finishSolarProjects: (state, action) => ({
      ...state,
      solarProjects: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    setCurrentSolarProject: (state, action) => ({
      ...state,
      currentSolarProject: action.payload
    }),


    requestAddSupportTicket: (state) => ({
      ...state,
      ticket: { loading: true, msg: null, error: false, data: null },

    }),
    finishSupportTicket: (state, action) => ({
      ...state,
      ticket: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestSupportTickets: (state) => ({
      ...state,
      tickets: { loading: true, msg: null, error: false, data: null },

    }),

    finishSupportTickets: (state, action) => ({
      ...state,
      tickets: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),
    requestAddOrder: (state) => ({
      ...state,
      order: { loading: true, msg: null, error: false, data: null },

    }),
    finishAddOrder: (state, action) => ({
      ...state,
      order: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestRep: (state) => ({
      ...state,
      rep: { loading: true, msg: null, error: false, data: null },

    }),
    finishRep: (state, action) => ({
      ...state,
      rep: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestReps: (state) => ({
      ...state,
      reps: { loading: true, msg: null, error: false, data: null },

    }),
    finishReps: (state, action) => ({
      ...state,
      reps: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestRepDetails: (state) => ({
      ...state,
      repDetails: { loading: true, msg: null, error: false, data: null },

    }),
    finishRepDetails: (state, action) => ({
      ...state,
      repDetails: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestUpdateRepDetails: (state) => ({
      ...state,
      updateRepDetails: { loading: true, msg: null, error: false, data: null },

    }),


    requestStockManage: (state) => ({
      ...state,
      stockManage: { loading: true, msg: null, error: false, data: null },

    }),

    finishStockManage: (state, action) => ({
      ...state,
      stockManage: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestEditStockManage: (state) => ({
      ...state,
      stockManageEdit: { loading: true, msg: null, error: false, data: null },

    }),

    finishAddStockManage: (state, action) => ({
      ...state,
      stockManageEdit: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestUpdateStockManage: (state) => ({
      ...state,
      stockManageUpdate: { loading: true, msg: null, error: false, data: null },

    }),

    finishUpdateStockManage: (state, action) => ({
      ...state,
      stockManageUpdate: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestPublicStock: (state, action) => ({
      ...state,
      publicStock: { loading: true, msg: null, error: false, data: null },
    }),

    finishPublicStock: (state, action) => ({
      ...state,
      publicStock: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    requestAddSentWarehouse: (state) => ({
      ...state,
      sentWarehouse: { loading: true, msg: null, error: false, data: null },

    }),
    finishAddSentWarehouse: (state, action) => ({
      ...state,
      sentWarehouse: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestCurrentWarehouse: (state) => ({
      ...state,
      currentWarehouse: { loading: true, msg: null, error: false, data: null },

    }),
    finishCurrentWarehouse: (state, action) => ({
      ...state,
      currentWarehouse: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    requestAddClaim: (state) => ({
      ...state,
      claims: { loading: true, msg: null, error: false, data: null },

    }),
    finishAddClaim: (state, action) => ({
      ...state,
      claims: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestCurrentClaims: (state) => ({
      ...state,
      claims: { loading: true, msg: null, error: false, data: null },

    }),
    finishCurrentClaims: (state, action) => ({
      ...state,
      claims: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    finishSizingResult: (state, action) => ({
      ...state,
      sizingResult: {
        data: action.payload.data,
      },

    }),


    setCurrentQuote: (state, action) => ({
      ...state,
      currentQuote: action.payload,
    }),

    requestQuoteSavedList: (state) => ({
      ...state,
      quotes: { loading: true, msg: null, error: false, data: null },

    }),

    finishQuoteSavedList: (state, action) => ({
      ...state,
      quotes: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),


    setIrrigationInput: (state, action) => ({
      ...state,
      irrigationInput: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    setIrrigationResult: (state, action) => ({
      ...state,
      irrigationResult: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data,
      },
    }),

    requestCurrentOrder: (state, action) => ({ ...state }),

    finishCurrentOrder: (state, action) => ({
      ...state, currentOrder: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    requestUpdateCurrentOrder: (state, action) => ({ ...state }),

    finishUpdateCurrentOrder: (state, action) => ({
      ...state, currentOrder: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    finishAdminOrdersList: (state, action) => ({
      ...state, adminOrders: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    requestCategoriesList: (state, action) => ({
      ...state,
      categories: { loading: true, msg: null, error: false, data: null },
    }),

    finishCategoriesList: (state, action) => ({
      ...state, categories: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    requestProductsList: (state, action) => ({
      ...state,
      products: { loading: true, msg: null, error: false, data: null },
    }),

    finishProductsList: (state, action) => ({
      ...state, products: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    requestAddAlertMessage: (state, action) => ({
      ...state,
      alertMessage: { loading: true, msg: null, error: false, data: null },
    }),

    addAlertMessage: (state, action) => ({
      ...state,
      alertMessage: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    setAlertMessage: (state, action) => ({
      ...state, alertMessage: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),

    finishAddAlertMessage: (state, action) => ({
      ...state, alertMessage: {
        loading: action.payload.loading,
        msg: action.payload.msg,
        error: action.payload.error,
        data: action.payload.data
      }
    }),



    ///
  },
})

export const setupFirebase = () => async dispatch => {
  // const firebaseLoad = import("firebase/app")
  try {
    // const firebase = await firebaseLoad
    // await import("firebase/auth")
    // await import("firebase/firestore")
    await import('firebase/auth')
    await import('firebase/firestore')
    await import('firebase/storage')
    await import('firebase/analytics')
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
      auth = firebase.auth()
      db = firebase.firestore()
      firebase.analytics();
      if (process.env.NODE_ENV === 'development') {
        db.settings({
          host: "localhost:5004",
          ssl: false,
          experimentalForceLongPolling: true
        });
        auth.useEmulator('http://localhost:9099/')
        firebase.storage().useEmulator('localhost', 9199)
        // firebase.storage().useEmulator('localhost', 9199)
      }
    }
    dispatch(dbSlice.actions.dbReady(true))
  } catch (error) {
    console.log('error', error)
    return dispatch(dbSlice.actions.dbReady(true))
  }
}

export const login = (email, password) => async dispatch => {
  // start loading
  dispatch(dbSlice.actions.requestLogin())

  try {
    let result = await auth.signInWithEmailAndPassword(email, password)
    if (result) {
      dispatch(dbSlice.actions.finishLogin({ loading: true, data: result.additionalUserInfo.isNewUser, error: null }))
    }
  } catch (err) {
    console.log("login error", err)
    let msg = "Cannot login, please try again."
    if (err.code === "auth/user-not-found") {
      msg = "Please check that your email and password are correct"
    } else if (err.code === "auth/wrong-password") {
      msg = "Please check that your password is correct"
    } else if (err.code === "auth/email-already-in-use") {
      msg =
        "This email has already been used. You may have already registered before."
    } else {
      msg = err.message
    }
    dispatch(dbSlice.actions.finishLogin({ loading: false, error: { msg } }))
  }
}

export const register = (email, password) => async dispatch => {
  // start loading
  dispatch(dbSlice.actions.requestRegister())

  try {
    let result = await auth.createUserWithEmailAndPassword(email, password)
    if (result) {
      console.log("register result", result)
      dispatch(
        dbSlice.actions.finishRegister({
          loading: false,
          msg: null,
          error: null,
        })
      )
    }
  } catch (err) {
    console.log("register error", err)
    let msg = "Cannot register, please try again."
    if (err.code === "auth/user-not-found") {
      msg = "This email and password combination does not exist"
    } else if (err.code === "auth/wrong-password") {
      msg = "This email and password combination does not exist"
    } else if (err.code === "auth/email-already-in-use") {
      msg =
        "This email has already been used. You may have already registered before. Go to Login and then click Forgot Password to reset your password."
    } else {
      msg = err.message
    }
    dispatch(
      dbSlice.actions.finishRegister({
        loading: false,
        msg: null,
        error: { msg },
      })
    )
  }
}

export const forgotPassword = email => async dispatch => {
  // start loading
  dispatch(dbSlice.actions.requestForgotPassword())

  try {
    await auth.sendPasswordResetEmail(email)
    dispatch(
      dbSlice.actions.finishForgotPassword({
        loading: false,
        error: null,
        msg: "Check your email a reset link has been sent.",
      })
    )
  } catch (err) {
    console.log("forgot error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishForgotPassword({
        loading: false,
        error: { msg },
        msg: null,
      })
    )
  }
}

export const verifyEmail = () => async dispatch => {
  // start loading
  dispatch(dbSlice.actions.requestUpdateProfile())

  try {
    await auth.currentUser.sendEmailVerification()
    dispatch(
      dbSlice.actions.finishUpdateProfile({
        loading: false,
        error: null,
        msg: "Check your email for the verification link",
      })
    )
  } catch (err) {
    console.log("verifyEmail error", err)
    // const msg = err.message
    //not going to show an error
    // dispatch(
    //   dbSlice.actions.finishUpdateProfile({
    //     loading: false,
    //     error: { msg },
    //     msg: null,
    //   })
    // )
  }
}

export const getTowns = () => async dispatch => {
  dispatch(dbSlice.actions.requestTowns())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/towns`)
      .orderBy("name")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("TOWNS_DONE error", err)
    const msg = err.message
    //   dispatch({ type: "TOWNS_DONE", forgotError: { msg } })
    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const addTown = town => async dispatch => {
  dispatch(dbSlice.actions.requestTowns())
  try {
    await db.collection(`${process.env.GATSBY_DB}/towns`)
      .add(town)
    dispatch(getTowns())
  } catch (err) {
    console.log("town add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateTown = town => async dispatch => {
  dispatch(dbSlice.actions.requestTowns())
  try {
    const { id, ...thingToUpdate } = town
    await db
      .collection(`${process.env.GATSBY_DB}/towns`)
      .doc(id)
      .update(thingToUpdate)
    dispatch(getTowns())
  } catch (err) {
    console.log("town update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

// dirty function to overwrite towns
export const addTownsBatch = flows => async dispatch => {
  console.log("towns to add", flows)


  // delete old flows and replace
  let current = await db
    .collection(`${process.env.GATSBY_DB}/towns`)
    .get()
  const temp = []
  current.forEach(doc => {
    const detail = doc.data()
    temp.push({ ...detail, id: doc.id })
  })

  var batch = db.batch();

  temp.filter(a => flows.find(b => b.id !== a.id)).forEach(doc => {
    var aRef = db.collection(`${process.env.GATSBY_DB}/towns`).doc(doc.id);
    console.log('delete', doc.id)
    batch.delete(aRef);
  })

  flows.forEach(doc => {
    var aRef = db.collection(`${process.env.GATSBY_DB}/towns`).doc(doc.id);
    console.log('update', doc.id)
    batch.set(aRef, { ...doc });
  })

  try {
    await batch.commit()

    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: "Done",
        error: null,
        data: null,
      })
    )

  } catch (err) {
    console.log("finishTowns add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishTowns({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateUserProfile = profile => async (dispatch, getState) => {
  dispatch(dbSlice.actions.requestUpdateProfile())
  console.log("profile to update", profile)
  const state = getState()
  const userProfile = state.db.userProfile

  if (userProfile && userProfile.email !== profile.email) {
    try {
      console.log('changing email')
      await auth.currentUser.updateEmail(profile.email)
    } catch (error) {
      console.log('need to login again', { error })
      let msg = error.message
      if (error.code === 'auth/requires-recent-login') {
        msg = 'To change your email we require you to login again with you current email and password for security purposes.'
        dispatch(
          dbSlice.actions.finishUpdateProfile({
            loading: false,
            msg: null,
            error: { reauth: true, msg },
            data: null,
          })
        )
      } else {
        dispatch(
          dbSlice.actions.finishUpdateProfile({
            loading: false,
            msg: null,
            error: { msg },
            data: null,
          })
        )
      }
      return
    }
  }
  try {
    let { id, email, firstName, lastName, phone, companyName, vatNumber, address, created, warehouse } = profile

    if (userProfile && userProfile.created) {
      await db
        .collection(`${process.env.GATSBY_DB}/users`)
        .doc(id)
        .set({ email, firstName, lastName, phone, companyName, vatNumber, address, warehouse }, { merge: true })
    } else {
      if (!created) {
        created = firebase.firestore.FieldValue.serverTimestamp()
      }
      await db
        .collection(`${process.env.GATSBY_DB}/users`)
        .doc(id)
        .set({ email, firstName, lastName, phone, companyName, created, vatNumber, address, warehouse }, { merge: true })
    }

    getUserAndRoles(auth.currentUser, dispatch)
    dispatch(
      dbSlice.actions.finishUpdateProfile({
        loading: false,
        msg: null,
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("user update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateProfile({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getUsers = () => async dispatch => {
  dispatch(dbSlice.actions.requestUsers())

  try {
    const result = await db
      .collection(`${process.env.GATSBY_DB}/users`)
      .orderBy("created", "desc")
      .get()
    const roles = await db.collection(`${process.env.GATSBY_DB}/roles`).get()
    const tempRoles = []
    roles.forEach(doc => {
      const detail = doc.data()
      tempRoles.push({ ...detail, id: doc.id })
    })
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({
        ...detail,
        id: doc.id,
        roles: tempRoles.find(a => doc.id === a.id)
          ? tempRoles.find(a => doc.id === a.id).role
          : [],
      })
    })
    dispatch(
      dbSlice.actions.finishUsers({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("users error", err)
    const msg = err.message
    //   dispatch({ type: "TOWNS_DONE", forgotError: { msg } })
    dispatch(
      dbSlice.actions.finishUsers({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateRoles = (id, roles) => async dispatch => {
  dispatch(dbSlice.actions.requestUpdateRoles())
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/roles`)
      .doc(id)
      .set({ role: roles })
    dispatch(
      dbSlice.actions.finishUpdateRoles({
        loading: false,
        msg: null,
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("roles update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateRoles({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateUserEmail = (id, email) => async dispatch => {
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/users`)
      .doc(id)
      .set({ email }, { merge: true })
    dispatch(getUsers())
  } catch (err) {
    console.error("user update error", err)
  }
}

export const updateUserRep = (id, rep) => async dispatch => {
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/users`)
      .doc(id)
      .set({ rep }, { merge: true })
    dispatch(getUsers())
  } catch (err) {
    console.error("user update error", err)
  }
}


export const updateUserCompany = (id, companyName) => async dispatch => {
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/users`)
      .doc(id)
      .set({ companyName }, { merge: true })
    dispatch(getUsers())
  } catch (err) {
    console.error("user update error", err)
  }
}

async function getUserAndRoles(authUser, dispatch) {
  try {
    let userProfile = await db
      .doc(`${process.env.GATSBY_DB}/users/${authUser.uid}`)
      .get()
    const userRoles = await db
      .doc(`${process.env.GATSBY_DB}/roles/${authUser.uid}`)
      .get()
    if (!userProfile.exists) {
      //no user profile make one?
      console.error("no user profile")
      const newProfile = {
        id: authUser.uid,
        email: authUser.email,
        firstName: "",
        lastName: "",
        phone: "",
        companyName: "",
        vatNumber: "",
        address: "",
        warehouse: "Johannesburg",
        created: firebase.firestore.FieldValue.serverTimestamp(),
      }
      dispatch(updateUserProfile(newProfile))
    }
    authUser = {
      id: userProfile.id,
      uid: authUser.uid,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      providerData: authUser.providerData,
      roles: userRoles.exists ? userRoles.data() : null,
      ...userProfile.data(),
    }
    localStorage.setItem("authUser", JSON.stringify(authUser))
    dispatch(
      dbSlice.actions.authenticated({ profile: authUser, uid: authUser.id })
    )
  } catch (e) {
    console.log("catch", e)
  }
}

export const getSavedAuth = () => async dispatch => {
  const currentUser = !isBrowser
    ? null
    : JSON.parse(localStorage.getItem("authUser"))
  if (currentUser) {
    // getUserAndRoles(currentUser, dispatch)
    dispatch(
      dbSlice.actions.authenticated({
        profile: currentUser,
        uid: currentUser.id,
      })
    )
  }
}

export const startAuth = () => async dispatch => {
  if (auth && db) {
    auth.onAuthStateChanged(
      async authUser => {
        if (authUser) {
          getUserAndRoles(authUser, dispatch)
        } else {
          dispatch(dbSlice.actions.unAuthenticated())
        }
      },
      () => {
        console.log("auth gone?")
        dispatch(dbSlice.actions.unAuthenticated())
      }
    )

    // return () => {
    //   auth.onAuthStateChanged(() => console.log("unsubscribe authchanged"))
    // }
  }
}

export const getProductImages = products => async dispatch => {
  const storage = firebase.storage()
  products.map(async product => {
    if (product.image) {
      const pathReference = storage.ref(product.image)
      try {
        const img = await pathReference.getDownloadURL()
        dispatch(
          dbSlice.actions.connectProductImage({ id: product.id, url: img })
        )
      } catch (e) {
        console.log("try image error", e)
      }
    }
  })
}

export const getProducts = () => async dispatch => {
  dispatch(dbSlice.actions.requestProducts())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/products`)
      .orderBy("name")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishProducts({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
    dispatch(getProductImages(temp))
  } catch (err) {
    console.log("producst error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishProducts({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const addProduct = product => async dispatch => {
  dispatch(dbSlice.actions.requestProducts())
  const { imageFile, datasheetFile, ...normalProduct } = product
  console.log("file", imageFile, datasheetFile)
  try {
    const result = await db
      .collection(`${process.env.GATSBY_DB}/products`)
      .add(normalProduct)
    // dispatch(getProducts())
    dispatch(uploadProductFile(result.id, 'image', imageFile.name, imageFile))
    dispatch(uploadProductFile(result.id, 'datasheet', datasheetFile.name, datasheetFile))

  } catch (err) {
    console.log("product add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishProducts({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateProduct = product => async dispatch => {
  dispatch(dbSlice.actions.requestProducts())
  try {
    const { id, ...thingToUpdate } = product
    await db
      .collection(`${process.env.GATSBY_DB}/products`)
      .doc(id)
      .update(thingToUpdate)
    dispatch(getProducts())
  } catch (err) {
    console.log("product update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishProducts({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const removeProduct = product => async dispatch => {
  try {
    const { id } = product
    await db
      .collection(`${process.env.GATSBY_DB}/products`)
      .doc(id).delete()
    dispatch(getProducts())
  } catch (err) {
    console.log("product update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishProducts({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const uploadProductFile = (
  productId,
  propName,
  fileName,
  file
) => async dispatch => {
  const storageRef = firebase.storage().ref()
  const fileLink = `products/${Date.now()}` + fileName
  const imageRef = storageRef.child(fileLink)
  try {
    await imageRef.put(file)
    let fileObject = {}
    fileObject[propName] = fileLink
    await db
      .collection(`${process.env.GATSBY_DB}/products`)
      .doc(productId)
      .update(fileObject)
    console.log("done upload")
    dispatch(getProducts())
  } catch (e) {
    console.log("upload fail", e)
  }
}

export const basicPumpCalculation = (pumpValues, type, pumps) => async dispatch => {
  dispatch(
    dbSlice.actions.requestBasicPumpCalculation({
      pumpValues, type
    })
  )

  dispatch(addAudit('Pump Sizing', 1, { pumpValues, type }))

  const pumpResult = PumpCalulation(
    pumpValues.waterLevel,
    pumpValues.pumpDistance,
    pumpValues.pipeDiameter,
    pumpValues.pumpHeight,
    pumpValues.waterNeeded,
    pumpValues.town,
    pumpValues.totalWellDepth,
    type,
    pumps,
    pumpValues.customer,
    pumpValues.phone,
    pumpValues.email
  )

  // PumpCalulationAdjusted(
  //   pumpValues.waterLevel,
  //   pumpValues.pumpDistance,
  //   pumpValues.pipeDiameter,
  //   pumpValues.pumpHeight,
  //   pumpValues.waterNeeded,
  //   pumpValues.town,
  //   pumpValues.totalWellDepth,
  //   type,
  //   pumps,
  //   pumpValues.customer,
  //   pumpValues.phone,
  //   pumpValues.email)

  dispatch(
    dbSlice.actions.finishBasicPumpCalculation({
      loading: false,
      msg: null,
      error: null,
      data: pumpResult,
    })
  )
}

export const veichiCalculation = ({ powerRating,
  flow,
  town,
  customer,
  phone,
  email,
  voltage,
  panelMake,
  panelVMP,
  panelVOC,
  panelWatt,
  manual,
  drive,
  numberOfStrings,
  panelsPerString,
  hybrid,
  drives
}) => async dispatch => {
  dispatch(
    dbSlice.actions.requestVeichiCalculation({
      powerRating,
      flow,
      town,
      customer,
      phone,
      email,
      voltage,
      panelMake,
      panelVMP,
      panelVOC,
      panelWatt,
      manual,
      drive,
      numberOfStrings,
      panelsPerString,
      hybrid,
      drives
    })
  )

  dispatch(addAudit('Veichi Sizing', 1, {
    powerRating,
    flow,
    town,
    customer,
    phone,
    email,
    voltage,
    panelMake,
    panelVMP,
    panelVOC,
    panelWatt,
    manual,
    drive,
    numberOfStrings,
    panelsPerString,
    hybrid
  }))

  let pumpResult = veichiRecomendedCalculationTwo({
    powerRating,
    flow,
    town,
    customer,
    phone,
    email,
    voltage,
    panelMake,
    panelVMP,
    panelVOC,
    panelWatt,
    hybrid,
    drives
  })

  if (manual) {
    pumpResult = veichiManualCalculation({
      powerRating,
      flow,
      town,
      customer,
      phone,
      email,
      voltage,
      panelMake,
      panelVMP,
      panelVOC,
      panelWatt,
      manual,
      drive,
      numberOfStrings,
      panelsPerString,
      hybrid
    })
  }


  dispatch(
    dbSlice.actions.finishVeichiCalculation({
      loading: false,
      msg: null,
      error: null,
      data: { pumpResult, customer, phone, email },
    })
  )
}

export const requestLogout = () => async dispatch => {
  await auth.signOut()
  dispatch(dbSlice.actions.unAuthenticated())
}

//   function makeASandwichWithSecretSauce(forPerson) {
//     // We can invert control here by returning a function - the "thunk".
//     // When this function is passed to `dispatch`, the thunk middleware will intercept it,
//     // and call it with `dispatch` and `getState` as arguments.
//     // This gives the thunk function the ability to run some logic, and still interact with the store.
//     return function(dispatch) {
//       return fetchSecretSauce().then(
//         (sauce) => dispatch(makeASandwich(forPerson, sauce)),
//         (error) => dispatch(apologize('The Sandwich Shop', forPerson, error)),
//       );
//     };
//   }


export const addSupportTicket = info => async dispatch => {
  console.log("addSupportTicket", info)
  dispatch(
    dbSlice.actions.requestAddSupportTicket(info)
  )

  dispatch(addAudit('Support Ticket', 1, info))

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/support`)
      .add({ ...info, uid: auth.currentUser.uid, date: firebase.firestore.FieldValue.serverTimestamp() })
    dispatch(
      dbSlice.actions.finishSupportTicket({
        loading: false,
        msg: "Added Support Ticket. Check your email for confirmation.",
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("finishSupportTicket add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSupportTicket({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}



export const getSupportTickets = () => async dispatch => {
  dispatch(dbSlice.actions.requestSupportTickets())


  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/support`)
      .where("uid", "==", auth.currentUser.uid)
      .orderBy("date", "desc")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishSupportTickets({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishSupportTickets list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSupportTickets({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getAdminOrdersList = () => async dispatch => {
  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/orderssent`)
      .orderBy("date", "desc")
      .limit(100)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishAdminOrdersList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishAdminOrdersList list error", err)
    const msg = err.message
    dispatch(dbSlice.actions.finishAdminOrdersList({
      loading: false,
      msg: null,
      error: { msg },
      data: null,
    }))
  }
}

export const addOrder = info => async dispatch => {
  dispatch(
    dbSlice.actions.requestAddOrder(info)
  )

  dispatch(addAudit('Sent Order', 1, info))

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/orderssent`)
      .add({ ...info, uid: auth.currentUser.uid, date: firebase.firestore.FieldValue.serverTimestamp() })
    dispatch(
      dbSlice.actions.finishAddOrder({
        loading: false,
        msg: "Order sent. A copy will be sent to the email provided in the next few minutes.",
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("finishAddOrder add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddOrder({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}



export const addSentWarehouse = info => async dispatch => {
  dispatch(
    dbSlice.actions.requestAddSentWarehouse(info)
  )

  const batch = db.batch();

  info.forEach(doc => {
    const aRef = db.collection(`${process.env.GATSBY_DB}/warehouseout`).doc();
    batch.set(aRef, { ...doc, uid: auth.currentUser.uid, date: firebase.firestore.FieldValue.serverTimestamp() });
  })

  try {
    await batch.commit()

    dispatch(
      dbSlice.actions.finishAddSentWarehouse({
        loading: false,
        msg: "Saved sent products",
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("finishAddSentWarehouse add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddSentWarehouse({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}



export const getCurrentWarehouse = () => async dispatch => {
  dispatch(dbSlice.actions.requestCurrentWarehouse())


  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/warehouseout`)
      .orderBy("date", "desc")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishCurrentWarehouse({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("warehouseout list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishCurrentWarehouse({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const addClaim = info => async dispatch => {
  dispatch(
    dbSlice.actions.requestAddClaim(info)
  )

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/claims`)
      .add({ ...info, uid: auth.currentUser.uid, date: firebase.firestore.FieldValue.serverTimestamp() })
    dispatch(
      dbSlice.actions.finishAddClaim({
        loading: false,
        msg: "Added claim.",
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("finishAddClaim add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddClaim({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getCurrentClaims = () => async dispatch => {
  dispatch(dbSlice.actions.requestCurrentClaims())


  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/claims`)
      .orderBy("date", "desc")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishCurrentClaims({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("claims list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishCurrentClaims({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

// export const addWarranty = info => async dispatch => {
//   console.log("warranty", info)
//   dispatch(
//     dbSlice.actions.requestAddWarranty(info)
//   )

//   try {
//     await db
//       .collection(`${process.env.GATSBY_DB}/warranty`)
//       .add({...info, uid: auth.currentUser.uid})
//     dispatch(
//       dbSlice.actions.finishWarranty({
//         loading: false,
//         msg: "Added Warranty",
//         error: null,
//         data: null,
//       })
//     )
//   } catch (err) {
//     console.log("product add error", err)
//     const msg = err.message
//         dispatch(
//       dbSlice.actions.finishWarranty({
//         loading: false,
//         msg: null,
//         error: { msg },
//         data: null,
//       })
//     )
//   }
// }



// export const getWarrantyList = () => async dispatch => {
//   dispatch(dbSlice.actions.requestWarrantyList())


//   try {
//     let result = await db
//       .collection(`${process.env.GATSBY_DB}/warranty`)
//       .where("uid", "==", auth.currentUser.uid)
//       .orderBy("invoiceDate", "desc")
//       .get()
//     const temp = []
//     result.forEach(doc => {
//       const detail = doc.data()
//       temp.push({ ...detail, id: doc.id })
//     })
//     dispatch(
//       dbSlice.actions.finishWarrantyList({
//         loading: false,
//         msg: null,
//         error: false,
//         data: temp,
//       })
//     )
//   } catch (err) {
//     console.log("warranty list error", err)
//     const msg = err.message
//     dispatch(
//       dbSlice.actions.finishWarrantyList({
//         loading: false,
//         msg: null,
//         error: { msg },
//         data: null,
//       })
//     )
//   }
// }

// export const getAdminWarrantyList = () => async dispatch => {
//   dispatch(dbSlice.actions.requestWarrantyList())

//   try {
//     let result = await db
//       .collection(`${process.env.GATSBY_DB}/warranty`)
//       .orderBy("invoiceDate")
//       .get()
//     const temp = []
//     result.forEach(doc => {
//       const detail = doc.data()
//       temp.push({ ...detail, id: doc.id })
//     })
//     dispatch(
//       dbSlice.actions.finishWarrantyList({
//         loading: false,
//         msg: null,
//         error: false,
//         data: temp,
//       })
//     )
//   } catch (err) {
//     console.log("warranty list error", err)
//     const msg = err.message
//     dispatch(
//       dbSlice.actions.finishWarrantyList({
//         loading: false,
//         msg: null,
//         error: { msg },
//         data: null,
//       })
//     )
//   }
// }

export const addApplication = info => async dispatch => {
  dispatch(
    dbSlice.actions.requestAddApplication(info)
  )

  dispatch(addAudit('Agent Application', 1, info))

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/applications`).doc(auth.currentUser.uid)
      .set({ ...info, email: auth.currentUser.email })
    dispatch(
      dbSlice.actions.finishApplication({
        loading: false,
        msg: "Application complete. Please wait for us to review and approve your application.",
        error: null,
        data: null,
      })
    )
    // dispatch to update profile

    dispatch(updateRoles(auth.currentUser.uid, ['APPLICANT']))

    dispatch(updateUserProfile({ id: auth.currentUser.uid, email: auth.currentUser.email, firstName: info.firstName, lastName: info.lastName, phone: info.phone, companyName: info.businessName, vatNumber: info.vatNumber, address: info.deliveryAddress, created: firebase.firestore.FieldValue.serverTimestamp(), }))

  } catch (err) {
    console.log("application add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishApplication({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getBannerImage = () => async dispatch => {
  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/home/banner`)
      .get()

    dispatch(
      dbSlice.actions.addbanner(
        result.data(),
      )
    )
  } catch (err) {
    console.log("banner error", err)
    // put in a default
    dbSlice.actions.addbanner(
      {
        Picture: [],
      }
    )
  }


}


export const getAlertMessage = () => async dispatch => {
  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/home/alert`)
      .get()

    dispatch(
      dbSlice.actions.setAlertMessage(
        {
          loading: false,
          msg: null,
          error: null,
          data: result.data(),
        }
      )
    )
  } catch (err) {
    console.log("alert error", err)
    // put in a default
    dbSlice.actions.setAlertMessage(
      {
        loading: false,
        msg: null,
        error: null,
        data: { message: "" },
      }
    )
  }
}

export const addAlertMessage = message => async dispatch => {
  console.log("alert to add", message)
  dispatch(
    dbSlice.actions.requestAddAlertMessage({ message: message.message, alertType: message.alertType })
  )

  try {
    await db
      .doc(`${process.env.GATSBY_DB}/home/alert`)
      .set({ message: message.message, alertType: message.alertType })
    dispatch(
      dbSlice.actions.finishAddAlertMessage({
        loading: false,
        msg: "Alert message updated.",
        error: null,
        data: { message: message.message, alertType: message.alertType },
      })
    )
  } catch (err) {
    console.log("alert add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddAlertMessage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getUserApplication = (uid) => async dispatch => {
  dispatch(dbSlice.actions.requestUserApplication({ uid }))

  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/applications/${uid}`)
      .get()

    dispatch(
      dbSlice.actions.finishApplication({
        loading: false,
        msg: null,
        error: false,
        data: result.exists ? { uid, ...result.data() } : {},
      })
    )
  } catch (err) {
    console.log("getUserApplication error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishApplication({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const addFlows = flows => async dispatch => {
  console.log("flows to add", flows)
  dispatch(
    dbSlice.actions.requestAddFlows(flows)
  )

  // delete old flows and replace
  let current = await db
    .collection(`${process.env.GATSBY_DB}/flows`)
    .get()
  const temp = []
  current.forEach(doc => {
    const detail = doc.data()
    temp.push({ ...detail, id: doc.id })
  })

  var batch = db.batch();

  temp.filter(a => flows.find(b => b.id !== a.id)).forEach(doc => {
    var aRef = db.collection(`${process.env.GATSBY_DB}/flows`).doc(doc.id);
    console.log('delete', doc.id)
    batch.delete(aRef);
  })

  flows.forEach(doc => {
    var aRef = db.collection(`${process.env.GATSBY_DB}/flows`).doc(doc.id);
    console.log('update', doc.id)
    batch.set(aRef, { ...doc });
  })

  try {
    await batch.commit()

    dispatch(
      dbSlice.actions.finishAddFlows({
        loading: false,
        msg: "Done",
        error: null,
        data: null,
      })
    )

  } catch (err) {
    console.log("flows add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddFlows({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getFlowsList = () => async dispatch => {
  dispatch(dbSlice.actions.requestFlowsList())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/flows`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishFlowsList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("flows list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishFlowsList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

// export const addVeichiDrives = flows => async dispatch => {
//   console.log("viechi to add", flows)
//   dispatch(
//     dbSlice.actions.requestAddViechiDrives(flows)
//   )

//   var batch = db.batch();

//   flows.drives.forEach(doc => {
//     var aRef = db.collection(`${process.env.GATSBY_DB}/veichidrives`).doc(doc.id);
//     batch.set(aRef, {...doc});
//   })

//   flows.panels.forEach(doc => {
//     var aRef = db.collection(`${process.env.GATSBY_DB}/veichipanels`).doc(doc.id);
//     batch.set(aRef, {...doc});
//   })

//   try {
//     await batch.commit()

//     dispatch(
//       dbSlice.actions.finishAddVeichiDrives({
//         loading: false,
//         msg: "Done",
//         error: null,
//         data: null,
//       })
//     )

//   } catch (err) {
//     console.log("veichi add error", err)
//     const msg = err.message
//         dispatch(
//       dbSlice.actions.requestAddViechiDrives({
//         loading: false,
//         msg: null,
//         error: { msg },
//         data: null,
//       })
//     )
//   }
// }

export const getVeichiDrivesList = () => async dispatch => {
  dispatch(dbSlice.actions.requestVeichiDrivesList())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/drivesData`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })

    dispatch(
      dbSlice.actions.finishVeichiDrivesList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("drives list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishVeichiDrivesList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const getDownloads = () => async (dispatch, getState) => {
  dispatch(dbSlice.actions.requestDownloads())
  const state = getState()
  try {
    let result = null
    if (canAccessAdmin(state.db.userProfile.roles)) {
      result = await db
        .collection(`${process.env.GATSBY_DB}/downloads`)
        .orderBy("title")
        .get()
    } else {
      result = await db
        .collection(`${process.env.GATSBY_DB}/downloads`)
        .where("role", 'in', [...state.db.userProfile.roles.role, 'RESELLER'])
        .orderBy("title")
        .get()
    }

    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishDownloads({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
    dispatch(getDownloadFiles(temp))
  } catch (err) {
    console.log("finishDownloads list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishDownloads({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getDownloadFiles = downloads => async dispatch => {
  const storage = firebase.storage()
  downloads.map(async a => {
    if (a.file) {
      const pathReference = storage.ref(a.file)
      try {
        const file = await pathReference.getDownloadURL()
        dispatch(
          dbSlice.actions.connectDownloadUrls({ id: a.id, url: file })
        )
      } catch (e) {
        console.log("try file url error", e)
      }
    }
  })
}

export const uploadDownloadFile = (
  downloadId,
  propName,
  fileName,
  file
) => async dispatch => {
  const storageRef = firebase.storage().ref()
  const fileLink = `downloads/${Date.now()}` + fileName
  const imageRef = storageRef.child(fileLink)
  try {
    await imageRef.put(file)
    let fileObject = {}
    fileObject[propName] = fileLink
    await db
      .collection(`${process.env.GATSBY_DB}/downloads`)
      .doc(downloadId)
      .update(fileObject)
    console.log("done upload")
    dispatch(getDownloads())
  } catch (e) {
    console.log("upload fail", e)
  }
}


export const addDownload = download => async dispatch => {
  console.log("download", download)
  dispatch(dbSlice.actions.requestDownloads(download))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/downloads`)
      .add(download)
    dispatch(getDownloads())
  } catch (err) {
    console.log("download add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishDownloads({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const removeDownload = download => async dispatch => {
  console.log("download remove", download)
  dispatch(dbSlice.actions.requestDownloads({ download, extra: 'remove' }))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/downloads`).doc(download.id)
      .delete()
    dispatch(getDownloads())
  } catch (err) {
    console.log("download remove error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishDownloads({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateDownload = download => async dispatch => {
  dispatch(dbSlice.actions.requestDownloads({ download, extra: 'update' }))
  try {
    const { id, ...thingToUpdate } = download
    await db
      .collection(`${process.env.GATSBY_DB}/downloads`)
      .doc(id)
      .update(thingToUpdate)
    dispatch(getDownloads())
  } catch (err) {
    console.log("download update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishDownloads({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const addSizingSave = () => async (dispatch, getState) => {
  const state = getState()
  const lastBasicPumpInput = state.db.lastBasicPumpInput
  dispatch(
    dbSlice.actions.requestSizingSavedList(lastBasicPumpInput)
  )
  dispatch(addAudit('Saved Sizing', 1, lastBasicPumpInput))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/saved`)
      .add({ ...lastBasicPumpInput, uid: auth.currentUser.uid, date: new Date() })
    dispatch(
      dbSlice.actions.finishSizingSavedList({
        loading: false,
        msg: "Added Pump Sizing",
        error: null,
        data: null,
      })
    )
  } catch (err) {
    console.log("sizing saved add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSizingSavedList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getSizingSaveList = () => async dispatch => {
  dispatch(dbSlice.actions.requestSizingSavedList())

  let result
  try {
    result = await db
      .collection(`${process.env.GATSBY_DB}/saved`)
      .where("uid", "==", auth.currentUser.uid)  //'aa') 
      .orderBy("date", 'desc')
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishSizingSavedList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishSizingSavedList list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSizingSavedList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const removeSizingSave = id => async dispatch => {
  console.log("sizing remove", id)
  dispatch(dbSlice.actions.requestSizingSavedList({ id, extra: 'sizing remove' }))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/saved`).doc(id)
      .delete()
    dispatch(getSizingSaveList())
  } catch (err) {
    console.log("remove saving remove error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSizingSavedList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const addFeeback = feedback => async dispatch => {
  dispatch(dbSlice.actions.requestFeedback(feedback))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/feedback`)
      .add({ text: feedback, uid: auth.currentUser.uid, email: auth.currentUser.email, date: new Date() })

    dispatch(
      dbSlice.actions.finishFeedback({
        loading: false,
        msg: null,
        error: null,
        data: "done",
      }))
  } catch (err) {
    console.log("feedback add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishFeedback({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const addAudit = (thing, amount, extra) => async (dispatch, getState) => {
  const audit = { uid: auth.currentUser.uid, email: auth.currentUser.email, date: firebase.firestore.FieldValue.serverTimestamp(), thing, amount, extra }
  const state = getState()
  if (state.db.auditsOn) {
    try {
      await db
        .collection(`${process.env.GATSBY_DB}/rawaudit`)
        .add(audit)

    } catch (err) {
      console.log("audit add error", err)
    }
  } else {
    console.log("audit off: ", audit)
  }
}


export const getAudits = () => async dispatch => {
  dispatch(dbSlice.actions.requestAudits())

  let result
  try {
    result = await db
      .collection(`${process.env.GATSBY_DB}/rawaudit`)
      .orderBy("date", 'desc')
      .limit(50)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishAudits({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishAudits list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAudits({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getMpptList = () => async dispatch => {
  dispatch(dbSlice.actions.requestMpptList())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/mppt`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })

    console.log('mppt', { temp })

    dispatch(
      dbSlice.actions.finishMpptList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("mppt list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishMpptList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getRawNasaList = id => async dispatch => {
  dispatch(dbSlice.actions.requestRawNasa())

  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/rawNasa/${id}`)
      .get()

    console.log('raw nasa', result.data())

    dispatch(
      dbSlice.actions.finishRawNasa({
        loading: false,
        msg: null,
        error: false,
        data: result.data(),
      })
    )
  } catch (err) {
    console.log("raw nasa error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishRawNasa({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

// use this for pulling out stuff
export const getRawStuff = id => async dispatch => {
  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/batteries`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })

    console.log('raw stuff', { temp })

  } catch (err) {
    console.log('get raw stuff error', err)
  }
}

export const addMppts = flows => async dispatch => {
  console.log("mppt to add", flows)
  dispatch(
    dbSlice.actions.requestAddMppt(flows)
  )

  var batch = db.batch();

  flows.forEach(doc => {
    var aRef = db.collection(`${process.env.GATSBY_DB}/mppt`).doc(doc.id);
    batch.set(aRef, { ...doc });
  })

  try {
    await batch.commit()

    dispatch(
      dbSlice.actions.finishAddMppt({
        loading: false,
        msg: "Done",
        error: null,
        data: null,
      })
    )

  } catch (err) {
    console.log("mppt add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.requestAddMppt({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getStock = () => async dispatch => {
  dispatch(dbSlice.actions.requestStock())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/stockcount`)
      .orderBy("order")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishStock({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("stock error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishStock({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getBatteries = () => async dispatch => {
  dispatch(dbSlice.actions.requestBatteries())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/batteries`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishBatteries({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("Batteries error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishBatteries({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getInverters = () => async dispatch => {
  dispatch(dbSlice.actions.requestInverters())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/inverter`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishInverters({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("Inverters error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishInverters({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const getSolarProjects = () => async dispatch => {
  if (!auth.currentUser) {
    return
  }
  dispatch(dbSlice.actions.requestSolarProjects())
  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/solarprojects`)
      .orderBy("date", "desc")
      .where("uid", "==", auth.currentUser.uid)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishSolarProjects({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishSolarProjects error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSolarProjects({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const removeSolarProject = id => async dispatch => {
  dispatch(dbSlice.actions.requestSolarProjects({ id, extra: 'sizing remove' }))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/solarprojects`).doc(id)
      .delete()
    dispatch(getSolarProjects())
  } catch (err) {
    console.log("remove solar error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishSolarProjects({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


// i probably don't always have to put all the calls in this dispatch thing
// as long as I dispatch an update manually after
//   dispatch(dbSlice.actions.requestAddSolarProject(project))

export const addSolarProject = async (project) => {
  return db
    .collection(`${process.env.GATSBY_DB}/solarprojects`)
    .add({ uid: auth.currentUser.uid, date: new Date(), ...project })
}

export const updateSolarProject = async (id, project) => {
  return db
    .collection(`${process.env.GATSBY_DB}/solarprojects`)
    .doc(id)
    .update({ ...project, date: new Date() })
}


export const getIdToken = () => auth.currentUser.getIdToken()

export const getRep = () => async (dispatch, getState) => {
  const state = getState()
  const userProfile = state.db.userProfile
  dispatch(dbSlice.actions.requestRep({ userProfile }))

  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/reps/${userProfile.rep}`)
      .get()

    dispatch(
      dbSlice.actions.finishRep({
        loading: false,
        msg: null,
        error: false,
        data: result.exists ? { id: result.id, code: result.id, ...result.data() } : {},
      })
    )
  } catch (err) {
    console.log("no rep error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishRep({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const getReps = () => async dispatch => {
  dispatch(dbSlice.actions.requestReps())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/reps`)
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishReps({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("Reps error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishReps({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const getRepDetails = () => async (dispatch, getState) => {
  const state = getState()
  const userProfile = state.db.userProfile
  dispatch(dbSlice.actions.requestRepDetails({ userProfile }))

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/reps`)
      .where("uid", "==", userProfile.id)
      .get()

    console.log({ result })

    if (result.size > 0) {
      console.log({ id: result.docs[0].id, code: result.docs[0].id, ...result.docs[0].data() })
      dispatch(
        dbSlice.actions.finishRepDetails({
          loading: false,
          msg: null,
          error: false,
          data: { id: result.docs[0].id, code: result.docs[0].id, ...result.docs[0].data() },
        })
      )
    } else {
      dispatch(
        dbSlice.actions.finishRepDetails({
          loading: false,
          msg: null,
          error: null,
          data: null,
        })
      )
    }

  } catch (err) {
    console.log("no rep error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishRepDetails({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateRepDetails = info => async (dispatch, getState) => {
  dispatch(
    dbSlice.actions.requestUpdateRepDetails(info)
  )

  const state = getState()
  const userProfile = state.db.userProfile
  dispatch(dbSlice.actions.requestRepDetails({ userProfile }))

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/reps`).doc(info.code)
      .set({ uid: userProfile.id, name: info.name, email: info.email, phone: info.phone }, { merge: true })
    dispatch(
      dbSlice.actions.finishRepDetails({
        loading: false,
        msg: "You Rep details saved and will show in the agents home page.",
        error: null,
        data: { uid: userProfile.id, name: info.name, email: info.email, phone: info.phone, id: info.code, code: info.code },
      })
    )

  } catch (err) {
    console.log("updateRepDetails add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishRepDetails({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateCurrentOrder = info => async dispatch => {
  dispatch(dbSlice.actions.requestUpdateCurrentOrder(info))

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/currentorders`).doc(auth.currentUser.uid)
      .set({ uid: auth.currentUser.uid, ...info, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
    dispatch(getCurrentOrder())
  } catch (err) {
    console.log("current update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateCurrentOrder({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const addToCurrentOrder = info => async dispatch => {
  dispatch(dbSlice.actions.requestUpdateCurrentOrder(info))

  const currentDocRef = db.collection(`${process.env.GATSBY_DB}/currentorders`).doc(auth.currentUser.uid)
  try {
    await db.runTransaction(async (transaction) => {
      const currentDoc = await transaction.get(currentDocRef)
      if (!currentDoc.exists) {
        transaction.set(currentDocRef, {
          uid: auth.currentUser.uid,
          rows: [info],
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else {
        const currentOrder = currentDoc.data()
        // check if item already exists in row
        const rows = currentOrder.rows
        console.log('rows', rows)
        const index = rows.findIndex((i) => i.product.Id === info.product.Id)
        if (index !== -1) {
          // add to quantity
          rows[index].quantity = info.quantity + 1
          console.log('add quantity only')
          transaction.update(currentDocRef, {
            rows: [...rows],
            updated: firebase.firestore.FieldValue.serverTimestamp(),
          })
        } else {
          console.log('add to the rows', rows, info, [...rows, info])
          transaction.update(currentDocRef, {
            rows: [...rows, info],
            updated: firebase.firestore.FieldValue.serverTimestamp(),
          })
        }
      }
    })
    console.log('Transaction successfully committed!')
    dispatch(getCurrentOrder())
  } catch (err) {
    console.log('Transaction failed: ', err)
    Sentry.captureException(err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateCurrentOrder({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }

}

// wite a function to add multiple items to the current order
export const addMultipleToCurrentOrder = items => async dispatch => {
  dispatch(dbSlice.actions.requestUpdateCurrentOrder(items))

  const currentDocRef = db.collection(`${process.env.GATSBY_DB}/currentorders`).doc(auth.currentUser.uid)

  try {
    await db.runTransaction(async (transaction) => {
      const currentDoc = await transaction.get(currentDocRef)
      if (!currentDoc.exists) {
        transaction.set(currentDocRef, {
          uid: auth.currentUser.uid,
          rows: items,
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else {
        const currentOrder = currentDoc.data()
        // check if item already exists in row
        let rows = currentOrder.rows
        console.log('rows', rows)
        items.forEach((item) => {
          const index = rows.findIndex((i) => i.product.Id === item.product.Id)
          if (index !== -1) {
            // add to quantity
            rows[index].quantity = item.quantity + 1
            rows = [...rows]
          } else {
            rows = [...rows, item]
          }
        })

        transaction.update(currentDocRef, {
          rows: [...rows],
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
      }
    })
    console.log('Transaction successfully committed!')
    dispatch(getCurrentOrder())
  } catch (err) {
    console.log('Transaction failed: ', err)
    Sentry.captureException(err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateCurrentOrder({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }

}

export const getCurrentOrder = () => async (dispatch, getState) => {
  const state = getState()
  // we need to check if the items exist and get the new stock value
  const stock = state.db.products?.data

  dispatch(dbSlice.actions.requestCurrentOrder())

  try {
    let result = await db
      .doc(`${process.env.GATSBY_DB}/currentorders/${auth.currentUser.uid}`)
      .get()
    if (result.exists) {
      let detail = result.data()
      if (stock) {
        const temp = []
        detail.rows.forEach(item => {
          const stockItem = stock.find(a => a.Code === item.code)
          if (stockItem) {
            temp.push({ ...item, stock: getStockBasedOnProfileLocation({ location: state.db.userProfile.warehouse, product: stockItem }), product: stockItem })
          }
        })
        detail.rows = temp
      }
      dispatch(
        dbSlice.actions.finishCurrentOrder({
          loading: false,
          msg: null,
          error: false,
          data: { ...detail },
        })
      )
    } else {
      dispatch(
        dbSlice.actions.finishCurrentOrder({
          loading: false,
          msg: null,
          error: false,
          data: null,
        })
      )
    }

  } catch (err) {
    console.log("current order error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishCurrentOrder({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}





export const getStockManage = () => async dispatch => {
  dispatch(dbSlice.actions.requestStockManage())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/stock`)
      .orderBy("order")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishStockManage({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("stock manage error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishStockManage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


export const addStockManage = info => async (dispatch, getState) => {
  dispatch(
    dbSlice.actions.requestStockManage(info)
  )

  const state = getState()
  const userProfile = state.db.userProfile
  const { tableData, ...data } = info
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/stock`)
      .add({ lastEditor: userProfile.id, ...data, updated: firebase.firestore.FieldValue.serverTimestamp() })
    // dispatch(
    //   dbSlice.actions.finishAddStockManage({
    //     loading: false,
    //     msg: "Added Successfully.",
    //     error: null,
    //     data: null,
    //   })
    // )
    dispatch(getStockManage())


  } catch (err) {
    console.log("finishAddStockManage add error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddStockManage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const updateStockManage = info => async (dispatch, getState) => {
  dispatch(
    dbSlice.actions.requestUpdateStockManage("Update Stock")
  )

  const state = getState()
  const userProfile = state.db.userProfile
  const { id, tableData, datasheetFile, imageFile, ...data } = info
  try {
    if (datasheetFile && imageFile) {
      console.log('in upload')
      await dispatch(uploadStockProductFile(id, 'datasheet', datasheetFile.name, datasheetFile))
      await dispatch(uploadStockProductFile(id, 'image', imageFile.name, imageFile))
      console.log('after upload')

    }
    await db
      .collection(`${process.env.GATSBY_DB}/stock`).doc(id)
      .update({ ...data, lastEditor: userProfile.id, updated: firebase.firestore.FieldValue.serverTimestamp() })

    dispatch(
      dbSlice.actions.finishUpdateStockManage({
        loading: false,
        msg: null,
        error: null,
        data: null,
      }))

    dispatch(getStockManage())


  } catch (err) {
    console.log("finishAddStockManage update error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishUpdateStockManage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const bulkUpdateStockManage = rows => async (dispatch, getState) => {
  dispatch(
    dbSlice.actions.requestStockManage(rows)
  )

  const state = getState()
  const userProfile = state.db.userProfile
  const batch = db.batch();

  rows.forEach(info => {
    const { id, tableData, ...data } = info
    var aRef = db.collection(`${process.env.GATSBY_DB}/stock`).doc(id);
    batch.set(aRef, { ...data, lastEditor: userProfile.id, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
  })

  try {
    await batch.commit()
    dispatch(getStockManage())

  } catch (err) {
    console.log("bulk edit errors", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddStockManage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )

  }

}

export const removeStockManage = id => async (dispatch) => {
  dispatch(
    dbSlice.actions.requestStockManage(id)
  )

  try {
    await db
      .collection(`${process.env.GATSBY_DB}/stock`).doc(id)
      .delete()
    dispatch(getStockManage())

  } catch (err) {
    console.log("finishAddStockManage remove error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishAddStockManage({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const uploadStockProductFile = (
  productId,
  propName,
  fileName,
  file
) => async dispatch => {
  const storageRef = firebase.storage().ref()
  const fileLink = `stock/${Date.now()}` + fileName
  const imageRef = storageRef.child(fileLink)
  console.log('file', file)
  try {
    await imageRef.put(file)
    let fileObject = {}
    fileObject[propName] = fileLink
    await db
      .collection(`${process.env.GATSBY_DB}/stock`)
      .doc(productId)
      .update(fileObject)
    console.log("done upload")
  } catch (e) {
    console.log("upload fail", e)
  }
}

// export const getPublicStockImages = products => async dispatch => {
//   const storage = firebase.storage()
//   publicStock.map(async product => {
//     if (product.image) {
//       const pathReference = storage.ref(product.image)
//       try {
//         const img = await pathReference.getDownloadURL()
//         dispatch(
//           dbSlice.actions.connectProductImage({ id: product.id, url: img })
//         )
//       } catch (e) {
//         console.log("try image error", e)
//       }
//     }
//   })
// }

export const getPublicStock = () => async dispatch => {
  dispatch(dbSlice.actions.requestPublicStock())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/stockpublic`)
      .orderBy("order")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishPublicStock({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
    // dispatch(getProductImages(temp))
  } catch (err) {
    console.log("producst error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishPublicStock({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}


// export const addQuoteSave = (quote) => async (dispatch, getState) => {
//   dispatch(dbSlice.actions.requestQuoteSavedList())
//   dispatch(addAudit('Saved quote', 1, quote))
//   try {
//     await db
//       .collection(`${process.env.GATSBY_DB}/quotes`)
//       .add({...quote, uid: auth.currentUser.uid, date: new Date()})
//     dispatch(
//       dbSlice.actions.finishQuoteSavedList({
//         loading: false,
//         msg: "Added Quote Sizing",
//         error: null,
//         data: null,
//       })
//     )
//   } catch (err) {
//     console.log("quote saved add error", err)
//     const msg = err.message
//         dispatch(
//       dbSlice.actions.finishQuoteSavedList({
//         loading: false,
//         msg: null,
//         error: { msg },
//         data: null,
//       })
//     )
//   }
// }

export const updateOrCreateQuote = (quote) => async (dispatch, getState) => {
  if (!quote.internalRef) {
    return
  }
  dispatch(addAudit('Update or saved saved quote', 1, quote))
  try {
    await db
      .doc(`${process.env.GATSBY_DB}/quotes/${quote.internalRef}`)
      .set({ ...quote, uid: auth.currentUser.uid, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
    dispatch(getQuoteSaveList())
  } catch (err) {
    console.log("quote saved add error", err)
  }
}

export const getQuoteSaveList = () => async dispatch => {
  dispatch(dbSlice.actions.requestQuoteSavedList())

  let result
  try {
    result = await db
      .collection(`${process.env.GATSBY_DB}/quotes`)
      .where("uid", "==", auth.currentUser.uid)  //'aa') 
      .orderBy("updated", 'desc')
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishQuoteSavedList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("finishQuoteSavedList list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishQuoteSavedList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const removeQuoteSave = id => async dispatch => {
  console.log("quote remove", id)
  dispatch(dbSlice.actions.requestQuoteSavedList({ id, extra: 'quote remove' }))
  try {
    await db
      .collection(`${process.env.GATSBY_DB}/quotes`).doc(id)
      .delete()
    dispatch(getQuoteSaveList())
  } catch (err) {
    console.log("remove quote remove error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishQuoteSavedList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getCategoriesList = () => async dispatch => {
  dispatch(dbSlice.actions.requestCategoriesList())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/categoriesData`)
      .orderBy("Order")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishCategoriesList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("categories list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishCategoriesList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const getProductsList = () => async dispatch => {
  dispatch(dbSlice.actions.requestProductsList())

  try {
    let result = await db
      .collection(`${process.env.GATSBY_DB}/productsData`)
      .orderBy("Order")
      .get()
    const temp = []
    result.forEach(doc => {
      const detail = doc.data()
      temp.push({ ...detail, id: doc.id })
    })
    dispatch(
      dbSlice.actions.finishProductsList({
        loading: false,
        msg: null,
        error: false,
        data: temp,
      })
    )
  } catch (err) {
    console.log("ProductsList list error", err)
    const msg = err.message
    dispatch(
      dbSlice.actions.finishProductsList({
        loading: false,
        msg: null,
        error: { msg },
        data: null,
      })
    )
  }
}

export const uploadProfileFile = async (
  fileName,
  file
) => {
  console.log('uploading profile file', fileName, file)
  const storageRef = firebase.storage().ref()
  const fileLink = `users/${auth.currentUser.uid}/` + fileName
  const imageRef = storageRef.child(fileLink)
  try {
    await imageRef.put(file)
    await db
      .collection(`${process.env.GATSBY_DB}/users`)
      .doc(auth.currentUser.uid)
      .update({ "profileImage": fileLink })
    // dispatch(getUserAndRoles())
  } catch (e) {
    console.log("upload fail", e)
  }
}

export const getFileUrl = filePath => {
  const storage = firebase.storage()
  const pathReference = storage.ref(filePath)
  return pathReference.getDownloadURL()
}




export const addFromBackup = info => async dispatch => {
  // console.log("backup", backup)

  var batch = db.batch();

  //   nasaBackup.forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/rawNasa`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })


  // batteriesBackup.forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/batteries`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })




  // backup.flows.forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/flows`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })

  // backup.towns.map(a=>({...a,  location: new firebase.firestore.GeoPoint(a.location.Ic, a.location.wc)})).forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/towns`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })

  // backup.flows.forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/flows`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })

  // backup.mppt.forEach(doc => {
  //   var aRef = db.collection(`${process.env.GATSBY_DB}/mppt`).doc(doc.id);
  //   batch.set(aRef, {...doc});
  // })


  try {
    await batch.commit()
    console.log('done!!!!')

  } catch (err) {
    console.log("backup errors", err)

  }

}


const mainSlice = createSlice({
  name: "main",
  initialState: 0,
  reducers: {
    increment: state => state + 1,
    decrement: state => state - 1,
  },
})

const createStore = () =>
  configureStore({
    reducer: { main: mainSlice.reducer, db: dbSlice.reducer },
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          isSerializable: value => {
            // check if firebase timestamp
            // const a =  value instanceOf Date;
            // const firebaseLoad = import("firebase")
            if (
              isBrowser &&
              value &&
              firebase.firestore &&
              (value instanceof firebase.firestore.Timestamp ||
                value instanceof firebase.firestore.GeoPoint ||
                // value instanceof firebase.auth.AdditionalUserInfo ||
                value.providerId)
            ) {
              //|| value instanceof firebase.UserInfo
              return true
            } else {
              return isPlain(value)
            }
            // value.nanoseconds?true:isPlain(value)
          },
        },
      }),
    ],
    // middleware: [...getDefaultMiddleware(), thunk]
  })

export default createStore

export const { increment, decrement } = mainSlice.actions
export const {
  finishLogin,
  finishForgotPassword,
  finishRegister,
  finishUpdateProfile,
  finishBasicPumpCalculation,
  finishSupportTicket,
  finishApplication,
  setBasicPumpSizingFromSaved,
  clearPumpCalculation,
  clearVeichiCalculation,
  finishVeichiCalculation,
  setAuditsOn,
  setCurrentSolarProject,
  finishAddOrder,
  finishRep,
  finishRepDetails,
  finishUpdateStockManage,
  finishAddSentWarehouse,
  finishAddClaim,
  finishSizingResult,
  setIrrigationInput,
  setIrrigationResult,
  finishAddAlertMessage,
} = dbSlice.actions

import roundTo from "round-to";
import uniqBy from "lodash/uniqBy"
import maxBy from "lodash/maxBy"

const systemLossFactor = 0.02; // 2%

const hourlyYield = [
  { hour: '06:00', percent: 0 },
  { hour: '07:00', percent: 3 },
  { hour: '08:00', percent: 32 },
  { hour: '09:00', percent: 50 },
  { hour: '10:00', percent: 75 },
  { hour: '11:00', percent: 90 },
  { hour: '12:00', percent: 100 },
  { hour: '13:00', percent: 99 },
  { hour: '14:00', percent: 85 },
  { hour: '15:00', percent: 65 },
  { hour: '16:00', percent: 30 },
  { hour: '17:00', percent: 1 },
  { hour: '18:00', percent: 0 },
]

const frictionCalculation = (pipeDiameter, peakFlowAtWell, pumpDistance) => {
  return (608704451 / pipeDiameter ** 4.8655) *
    (((peakFlowAtWell / 1000) * 16.666667) / 150) ** 1.85 *
    (pumpDistance / 100)
}

const peakFlowAtWellCalculation = (staticWaterLevel, pump) => {
  const flow = pump.flow.find((flow, index) => Math.round(staticWaterLevel) <= index)
  return flow ? roundTo(flow * (1 - systemLossFactor), 2) : 0; // not sure what to return if no flow
}

const totalDynamicHeadCalculation = (pipeFrictionLoss, staticWaterLevel, pumpHeight) => roundTo(parseFloat(pipeFrictionLoss) + parseFloat(staticWaterLevel) + parseFloat(pumpHeight), 2)

export const PumpCalulation = (
  waterLevel = 20, // static water level
  pumpDistance = 10,
  pipeDiameter = 32,
  pumpHeight = 5,
  waterNeeded = 3000,
  town = null,
  totalWellDepth = 40,
  type = 'all',
  pumps,
  customer,
  phone,
  email
) => {

  const calculatedPumps = pumps.map(a => {
    const peakFlowAtWell = peakFlowAtWellCalculation(waterLevel, a)
    const totalFriction = roundTo(frictionCalculation(pipeDiameter, peakFlowAtWell, pumpDistance), 2)
    const totalDynamicHead = totalDynamicHeadCalculation(totalFriction, waterLevel, pumpHeight)
    const peakFlowAtTDHAfterLoss = peakFlowAtWellCalculation(totalDynamicHead, a)

    const monthsFlowsPerDay = town.sunHours.map(monthHours => {
      const litres = peakFlowAtTDHAfterLoss * monthHours
      return Math.round(litres)
    })

    const averageflowPerDay = Math.round(monthsFlowsPerDay.reduce((accumulator, currentValue) => accumulator + currentValue) / 12)
    const pumpWorks = waterNeeded <= averageflowPerDay

    const averageFlowPerHour = hourlyYield.map(hour => {
      return { ...hour, flow: Math.round(hour.percent / 100 * peakFlowAtTDHAfterLoss) }
    })

    const latitude = Math.round(town.location.latitude)  // towns().find(t=>t.value===town).latitude
    const panelAngle = `${latitude}° & ${latitude + 5}°`

    // const installDepth = a.type ==='MultiFlow' &&  parseFloat(waterLevel)+30 < totalWellDepth? parseFloat(totalWellDepth)-5 : parseFloat(waterLevel)+30 < totalWellDepth ? parseFloat(waterLevel)+30 : parseFloat(totalWellDepth)-5

    let installDepth = parseFloat(totalWellDepth) - 5;


    if (a.type.toLowerCase() === 'multiflow') {
      // multiflow can go deep
      // addition - can only got to max pump number, pull from the name
      const multiflowMax = parseInt(a.pump.match(/\d+/g)[0])

      if (parseFloat(waterLevel) + 30 <= parseFloat(totalWellDepth)) {
        if (multiflowMax > 1 && multiflowMax <= parseFloat(totalWellDepth)) {
          installDepth = multiflowMax
        } else {
          installDepth = parseFloat(totalWellDepth) - 5
        }
      } else {
        installDepth = parseFloat(waterLevel) + 25
      }
    } else {
      // spitfire version
      let levelLimit = 30;
      if (a.name.toLowerCase().match(/\bgriffon\b/g)) {
        levelLimit = 20;
      }

      if (parseFloat(waterLevel) + 30 < parseFloat(totalWellDepth)) {
        installDepth = parseFloat(waterLevel) + levelLimit
      } else {
        installDepth = parseFloat(waterLevel) + levelLimit - 5
      }

    }

    if (installDepth >= parseFloat(totalWellDepth)) {
      installDepth = parseFloat(totalWellDepth) - 1
    }

    let cableNote = parseFloat(installDepth) > 100 ? 'Use 6mm Submersible Cable' : 'Use 4mm Submersible Cable'
    if (a.type.toLowerCase() === 'multiflow') {
      cableNote = parseFloat(installDepth) > 150 ? 'Use 4mm 4 core Submersible Cable' : 'Use 2.5mm 4 core Submersible Cable'
    }

    let rope = 'Polypropylene Rope 6mm'
    if (a.type.toLowerCase() === 'multiflow' || parseFloat(installDepth) >= 100) {
      rope = 'Polypropylene Rope 8mm'
    }

    // const pumpCosts = prices.find(p => a.pumpId === p.productId)
    // const panelCosts = prices.find(p => a.panelId === p.productId)

    // console.log('peak flow', a.name, peakFlowAtWell)
    // console.log('friction', a.name, totalFriction)
    // console.log('total dynamic head', a.name, totalDynamicHead)
    // console.log('peakFlowAtTDHAfterLoss', a.name, peakFlowAtTDHAfterLoss)
    // console.log('month flows', a.name, monthsFlowsPerDay)
    // console.log('averageMonthlyFlow', a.name, averageflowPerDay)
    // console.log('works', a.name, pumpWorks)

    return { ...a, town: town.name, peakFlowAtWell, totalFriction, totalDynamicHead, peakFlowAtTDHAfterLoss, monthsFlowsPerDay, averageflowPerDay, pumpWorks, averageFlowPerHour, latitude, panelAngle, cableNote, installDepth, type: a.type, customer, phone, email, rope, waterLevel }
  })

  const normalResult = calculatedPumps.filter(pump => pump.averageflowPerDay > 0).sort((a, b) => a.averageflowPerDay - b.averageflowPerDay)

  if (normalResult.filter(pump => pump.pumpWorks).length > 0 && normalResult.filter(pump => pump.pumpWorks).find(b => b.type.toLowerCase() === 'multiflow')) {
    return normalResult
  }
  else {
    console.log('do second calculation multiflow')
    const multiflowPumps = PumpCalculationMultiflow(waterLevel, pumpDistance, pipeDiameter, pumpHeight, waterNeeded, town, totalWellDepth, type, pumps, customer, phone, email)
    return [...normalResult.filter(b => b.type.toLowerCase() !== 'multiflow'), ...multiflowPumps]
  }

  /*
  
    // get the sun hours, maybe do for individual later
  
    const averageHours = sunTowns[town].reduce((accumulator, currentValue) => accumulator + currentValue)/12
    console.log('averageHours', averageHours)
    const averageFlowPerHour = waterNeeded/averageHours
    console.log('averageFlowPerHour', averageFlowPerHour)
    // filter pumps that satisfy the flow
    // then show the range by price? for this head it will give u so much water... 
  
    const pumpsFiltered = pumps.map(aPump => {
      const roundedWaterLevel = Math.round(waterLevel) // need integer for the flow array
      const basicFlow = aPump.flow[roundedWaterLevel]
      
      if(basicFlow){ // check if it matches any flow
        const friction = frictionCalculation(pipeDiameter, basicFlow, pumpDistance)
        const totalHead = Math.round(friction + waterLevel + pumpHeight)
        const newFlow = aPump.flow[totalHead]
        console.log('basic net', basicFlow, newFlow)
        return {calulatedFlow: newFlow, calulatedFlowPerDay: Math.round(newFlow*averageHours), frictionLoss: friction, totalDHead: totalHead, ...aPump};
      }else{
        // doesn't match thow it out
        return {calulatedFlow: 0, ...aPump};
      } 
    })
    .filter(aPump=>aPump.calulatedFlow >= averageFlowPerHour)
    .sort((a,b)=>a.calulatedFlow-b.calulatedFlow)
    console.log('e pumps', pumps, pumpsFiltered)
  
    return pumpsFiltered
    */
}

// so we going to use this calculation. If we cannot work out a pump. This will give us a multiflow pump using the friction calculation from the other side of the pipe.
export const PumpCalculationMultiflow = (
  waterLevel = 20, // static water level
  pumpDistance = 10,
  pipeDiameter = 32,
  pumpHeight = 5,
  waterNeeded = 3000,
  town = null,
  totalWellDepth = 40,
  type = 'all',
  pumps,
  customer,
  phone,
  email
) => {

  // give the water per hour for each month
  const monthsFlowsPerHour = town.sunHours.map(monthHours => {
    const litres = waterNeeded / monthHours
    return Math.round(litres)
  })
  const aveLitresPerHour = Math.round(monthsFlowsPerHour.reduce((accumulator, currentValue) => accumulator + currentValue) / 12)

  let dynamicHeads = []
  // here we were iterating over heads, but then it only really gets the last one. So we just do the one we need

  // for(let i=aveLitresPerHour/2; i<aveLitresPerHour*2; i+=5){
  // const totalFriction = roundTo(frictionCalculation(pipeDiameter, aveLitresPerHour, pumpDistance),2)
  // const totalDynamicHead = totalDynamicHeadCalculation(totalFriction, waterLevel, pumpHeight)
  // dynamicHeads.push(totalDynamicHead)
  // // const peakFlowAtTDHAfterLoss = peakFlowAtWellCalculation(totalDynamicHead, a)
  // }
  const totalFriction = roundTo(frictionCalculation(pipeDiameter, aveLitresPerHour, pumpDistance), 2)
  const totalDynamicHead = totalDynamicHeadCalculation(totalFriction, waterLevel, pumpHeight)
  dynamicHeads.push(totalDynamicHead)

  let monthsFlowsPerDay

  const onlyMultiPumps = pumps.filter(b => b.type.toLowerCase() === 'multiflow')

  // duo name contains 200 in the name
  const duo = onlyMultiPumps.filter(b => b.name.toLowerCase().match(/\b200\b/g))
  // filter out the duo
  const rest = onlyMultiPumps.filter(b => !b.name.toLowerCase().match(/\b200\b/g))

  // prioritize duo first (need to look at making this more dynamic)
  const duoFirst = [...duo, ...rest]

  const results = dynamicHeads.map(a => {
    let pumpWanted
    let peakFlowAtTDHAfterLoss
    for (let i = 0; i < duoFirst.length; i++) {
      const pump = duoFirst[i]
      peakFlowAtTDHAfterLoss = peakFlowAtWellCalculation(a, pump)
      if (peakFlowAtTDHAfterLoss >= aveLitresPerHour) {
        pumpWanted = pump
        break;
      }
    }

    const totalDynamicHead = a


    monthsFlowsPerDay = town.sunHours.map(monthHours => {
      const litres = aveLitresPerHour * monthHours
      return Math.round(litres)
    })

    // because of rounding we need to show what he put in
    let averageflowPerDay = waterNeeded // Math.round(monthsFlowsPerDay.reduce((accumulator, currentValue) => accumulator + currentValue)/12)
    const pumpWorks = true

    // we need to adjust flow per day with the input of the user
    const averageFlowPerHour = hourlyYield.map(hour => {
      return { ...hour, flow: Math.round(hour.percent / 100 * aveLitresPerHour) }
    })

    peakFlowAtTDHAfterLoss = maxBy(averageFlowPerHour, 'flow').flow


    const latitude = Math.round(town.location.latitude)  // towns().find(t=>t.value===town).latitude
    const panelAngle = `${latitude}° & ${latitude + 5}°`
    let installDepth = parseFloat(totalWellDepth) - 5;

    if (!pumpWanted) {
      return { pumpWorks: false }
    }

    const maxDepthPumpUnderWater = parseFloat(pumpWanted.maxDepth ?? 25)

    if ((parseFloat(waterLevel) + maxDepthPumpUnderWater) <= parseFloat(totalWellDepth)) {
      installDepth = parseFloat(waterLevel) + maxDepthPumpUnderWater - 5
    } else if (parseFloat(waterLevel) + 30 <= parseFloat(totalWellDepth)) {
      installDepth = parseFloat(totalWellDepth) - 5
    } else {
      installDepth = parseFloat(waterLevel) + 25
    }

    if (installDepth >= parseFloat(totalWellDepth)) {
      installDepth = parseFloat(totalWellDepth) - 1
    }

    let cableNote = parseFloat(installDepth) > 60 ? 'Use 6mm Submersible Cable' : 'Use 4mm Submersible Cable'
    if (pumpWanted.type.toLowerCase() === 'multiflow') {
      cableNote = parseFloat(installDepth) > 150 ? 'Use 4mm 4 core Submersible Cable' : 'Use 2.5mm 4 core Submersible Cable'
    }

    return { ...pumpWanted, town: town.name, totalFriction, totalDynamicHead, monthsFlowsPerDay, peakFlowAtTDHAfterLoss, pumpWorks, averageflowPerDay, averageFlowPerHour, latitude, panelAngle, cableNote, installDepth, type: pumpWanted.type, customer, phone, email, waterLevel }


    // return {totalDynamicHead: a, peakFlowAtTDHAfterLoss, pump: pumpWanted}
  })
    .sort((a, b) => a.averageflowPerDay - b.averageflowPerDay).filter(a => a.pumpWorks)

  console.log('results', results)


  return uniqBy(results, 'id')

}




// attempt to adjust to include more pumps May friction calculation issue, not using anymore 19 April 2023
export const PumpCalulationAdjusted = (
  waterLevel = 20, // static water level
  pumpDistance = 10,
  pipeDiameter = 32,
  pumpHeight = 5,
  waterNeeded = 3000,
  town = null,
  totalWellDepth = 40,
  type = 'all',
  pumps,
  customer,
  phone,
  email
) => {
  const calculatedPumps = pumps.map(a => {

    // get the litres per monthly based on the month sun hours, water needed per day / sun hours for each month
    // get the average litres per hour, sum(litres per month)  / 12
    // get total friction, friction calculation with average litres per hour
    // get the dynamic head, sum (totalFriction, waterLevel, pumpHeight)
    // get the peak flow at this dynamic head, use the flows to find the first flow that matches the dynamic head minus the loss
    // carry on with the old calculation, works out the average flow per day based on sun hours and if waterNeeded <= average flow per day pump is ok

    // give the water per hour for each month
    const monthsFlowsPerHour = town.sunHours.map(monthHours => {
      const litres = waterNeeded / monthHours
      return Math.round(litres)
    })
    const aveLitresPerHour = Math.round(monthsFlowsPerHour.reduce((accumulator, currentValue) => accumulator + currentValue) / 12)

    const totalFriction = roundTo(frictionCalculation(pipeDiameter, aveLitresPerHour, pumpDistance), 2)
    const totalDynamicHead = totalDynamicHeadCalculation(totalFriction, waterLevel, pumpHeight)
    const peakFlowAtTDHAfterLoss = peakFlowAtWellCalculation(totalDynamicHead, a)


    const monthsFlowsPerDay = town.sunHours.map(monthHours => {
      const litres = peakFlowAtTDHAfterLoss * monthHours
      return Math.round(litres)
    })

    const averageflowPerDay = Math.round(monthsFlowsPerDay.reduce((accumulator, currentValue) => accumulator + currentValue) / 12)
    const pumpWorks = waterNeeded <= averageflowPerDay

    const averageFlowPerHour = hourlyYield.map(hour => {
      return { ...hour, flow: Math.round(hour.percent / 100 * peakFlowAtTDHAfterLoss) }
    })


    const latitude = Math.round(town.location.latitude)  // towns().find(t=>t.value===town).latitude
    const panelAngle = `${latitude}° & ${latitude + 5}°`

    // const installDepth = a.type ==='MultiFlow' &&  parseFloat(waterLevel)+30 < totalWellDepth? parseFloat(totalWellDepth)-5 : parseFloat(waterLevel)+30 < totalWellDepth ? parseFloat(waterLevel)+30 : parseFloat(totalWellDepth)-5

    let installDepth = parseFloat(totalWellDepth) - 5;


    if (a.type.toLowerCase() === 'multiflow') {
      // multiflow can go deep
      // addition - can only got to max pump number, pull from the name
      const multiflowMax = parseInt(a.pump.match(/\d+/g)[0])

      if (parseFloat(waterLevel) + 30 <= parseFloat(totalWellDepth)) {
        if (multiflowMax > 1) {
          installDepth = multiflowMax
        } else {
          installDepth = parseFloat(totalWellDepth) - 5
        }
      } else {
        installDepth = parseFloat(waterLevel) + 25
      }
    } else {
      // spitfire version
      let levelLimit = 30;
      if (a.name.toLowerCase().match(/\bgriffon\b/g)) {
        levelLimit = 20;
      }

      if (parseFloat(waterLevel) + 30 < parseFloat(totalWellDepth)) {
        installDepth = parseFloat(waterLevel) + levelLimit
      } else {
        installDepth = parseFloat(waterLevel) + levelLimit - 5
      }

    }

    if (installDepth >= parseFloat(totalWellDepth)) {
      installDepth = parseFloat(totalWellDepth) - 1
    }

    let cableNote = parseFloat(installDepth) > 60 ? 'Use 6mm Submersible Cable' : 'Use 4mm Submersible Cable'
    if (a.type.toLowerCase() === 'multiflow') {
      cableNote = parseFloat(installDepth) > 150 ? 'Use 4mm 4 core Submersible Cable' : 'Use 2.5mm 4 core Submersible Cable'
    }

    // const pumpCosts = prices.find(p => a.pumpId === p.productId)
    // const panelCosts = prices.find(p => a.panelId === p.productId)

    // console.log('peak flow', a.name, peakFlowAtWell)
    // console.log('friction', a.name, totalFriction)
    // console.log('total dynamic head', a.name, totalDynamicHead)
    // console.log('peakFlowAtTDHAfterLoss', a.name, peakFlowAtTDHAfterLoss)
    // console.log('month flows', a.name, monthsFlowsPerDay)
    // console.log('averageMonthlyFlow', a.name, averageflowPerDay)
    // console.log('works', a.name, pumpWorks)

    return { ...a, town: town.name, totalFriction, totalDynamicHead, peakFlowAtTDHAfterLoss, pumpWorks, averageflowPerDay, averageFlowPerHour, latitude, panelAngle, cableNote, installDepth, type: a.type, customer, phone, email }
  })

  // console.log('calculatedPumps not filtered', calculatedPumps)//.sort((a,b)=>a.averageflowPerDay-b.averageflowPerDay))
  // console.log('calculatedPumps', calculatedPumps.filter(pump=>pump.pumpWorks).sort((a,b)=>a.averageflowPerDay-b.averageflowPerDay))

  return calculatedPumps.filter(pump => pump.averageflowPerDay > 0).sort((a, b) => a.averageflowPerDay - b.averageflowPerDay)
}

